import Request from "../utils/request";

export async function list(productCode: string, page: number = 1, includeOptions = false) {
  const includes = includeOptions ? "&includes=options" : "";
  const url = `/api/admin/purchases?product_code=${productCode}&page=${page}${includes}`
  const response = await Request.get(url);
  return response;
}

export async function create(json: object) {
  const response = await Request.post(`/api/admin/purchases`, json);
  return response.purchase;
}

export async function update(uuid: string, json: object) {
  const response = await Request.patch(`/api/admin/purchases/${uuid}`, json);
  return response.purchase;
}

export async function destroy(uuid: string) {
  const response = await Request.destroy(`/api/admin/purchases/${uuid}`);
  return response.purchase;
}

export default {
  list,
  update,
  create,
  destroy
};
