import Request from "../utils/request";

export async function list(sendableUuid: string, page: number = 1) {
  const url = `/api/admin/recipients?uuid=${sendableUuid}&page=${page}`
  const response = await Request.get(url);
  return response;
}

export default {
  list
};
