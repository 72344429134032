import { createState } from "solid-js";
import { For, Show } from "solid-js/web";
import { Modal, openModal, modalClick, closeModal } from "../components/modal";
import RecipientManager from "../models/recipient-manager";
import LoadSpin from "../icons/load-spin";
import ChevronLeft from "../icons/chevron-left";
import ChevronRight from "../icons/chevron-right";
import { datetime } from "../utils/format";

function EmptyRecipients() {
  return (
    <div class="empty empty-modal">
      <img src="/assets/img/recipient.svg" />
      <h1>No Recipients</h1>
      <p>
        No recipients have received this message yet.
      </p>
    </div>
  );
}

function LoadingRecipients() {
  return (
    <div class="loading loading-modal">
      <LoadSpin />
    </div>
  );
}

export function createRecipientsModal(uuid) {
  const [state, setState] = createState({
    recipients: undefined,
    total: undefined,
    page: 1,
    pagesTotal: 1
  });

  function refreshRecipients(page = 1, e = null) {
    if (e) { e.preventDefault(); }
    RecipientManager.list(uuid, page).then((response) => {
      setState({
        recipients: response.recipients,
        total: response.total,
        page: response.page,
        pagesTotal: response.pages_total
      });
    });
  }

  return {
    openRecipientsModal: function(e) {
      e.preventDefault();
      openModal("#recipients-modal");
      refreshRecipients();
    },

    RecipientsModal: function() {
      return (
        <Modal id="recipients-modal" class="modal-long">
          <Show when={state.recipients && state.recipients.length > 0}>
            <h2>Recipients <span class="suffix">({ state.total })</span></h2>
            <div class="menu">
              <Show when={state.pagesTotal > 1}>
                <Show when={state.page > 1}>
                  <a href="#" onClick={refreshRecipients.bind(null, state.page - 1)}><ChevronLeft /> Prev</a>
                </Show>
                <span class="menu-paginate">{state.page} of {state.pagesTotal}</span>
                <Show when={state.page < state.pagesTotal}>
                  <a href="#" onClick={refreshRecipients.bind(null, state.page + 1)}>Next <ChevronRight /></a>
                </Show>
              </Show>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Sent At</th>
                </tr>
              </thead>
              <tbody>
                <For each={state.recipients}>
                  {recipient =>
                    <tr>
                      <td>{recipient.user.email}</td>
                      <td>{datetime(recipient.email.sent_at || recipient.email.failed_at)}</td>
                    </tr>
                  }
                </For>
              </tbody>
            </table>
          </Show>
          <Show when={state.recipients && state.recipients.length <= 0}>
            <EmptyRecipients />
          </Show>
          <Show when={state.recipients === undefined}>
            <LoadingRecipients />
          </Show>
        </Modal>
      );
    }
  };
}
