import { createState } from "solid-js";
import { Show } from "solid-js/web";
import { Modal, modalClick, closeModal } from "../components/modal";
import { navigate } from "../components/router";
import ProductManager from "../models/product-manager";
import { queryJson, onInputMoney, onInputHex } from "../utils/form";
import { money, nameCode } from "../utils/format";

export default function(props) {
  const emptyProduct = {
    name: "",
    code: null,
    price: null,
    description: "",
    descriptor: "",
    asset: "",
    url: "",
    email: {
      from_email: "",
      from_name: "",
      reply_to_email: "",
      reply_to_name: "",
      primary_color: "",
      link_color: "",
      link_hover_color: ""
    }
  };
  const product = props.product || emptyProduct;
  const [state, setState] = createState({ product, isNew: !product.code });

  function submit(e) {
    e.preventDefault();
    const json = queryJson(
      "#product-edit-form",
      state.isNew ?
        ["name", "code", "price", "url", "descriptor", "description", "email__from_email"] :
        ["name", "code", "url",
         "email__from_email", "email__from_name", "email__reply_to_email", "email__reply_to_name",
         "email__primary_color", "email__link_color", "email__link_hover_color",
         "email__greeting", "email__signature", "email__footer", "email__address",
         "email__receipt_subject", "email__receipt_body", "email__receipt_button",
         "email__recover_subject", "email__recover_body", "email__recover_button"]
    );

    const productResponse = state.isNew ?
      ProductManager.create(json) :
      ProductManager.update(state.product.code, json);
    productResponse.then(function(product) {
      closeModal();
      if (!state.isNew && product.code !== props.product.code) {
        navigate(`/admin/products/${product.code}`);
      } else if (state.isNew) {
        navigate(`/admin/products/${product.code}/options?help`);
      } else if (props.onSave) {
        props.onSave(product);
      }
    });
  }

  function switchTab(tab, e) {
    e.preventDefault();
    document.querySelectorAll(".product-edit-tab").forEach((tab) => {
      tab.classList.add("hidden");
    });
    document.getElementById(`product-edit-${tab}`).classList.remove("hidden");

    document.querySelectorAll("#product-edit-tabs a.selected").forEach((anchor) => {
      anchor.classList.remove("selected");
    });
    e.target.classList.add("selected");
  }

  function switchSection(prefix, e) {
    e.preventDefault();
    document.querySelectorAll(`.${prefix}`).forEach((tab) => {
      tab.classList.add("hidden");
    });
    document.getElementById(`${prefix}-${e.target.value}`).classList.remove("hidden");
  }

  function autocode(e) {
    var field = document.querySelector("#product-edit-form [name=code]");
    if (state.isNew && !autocode.off) {
      field.value = nameCode(e.target.value);
    }
  }

  function autocodeConfigure(e) {
    var code = e.target.value;
    var name = document.querySelector("#product-edit-form [name=name]").value;
    autocode.off = nameCode(name) !== code;
    e.target.value = nameCode(code);
  }

  return (
    <Modal id="product-edit-modal" class="modal-long">
      <form id="product-edit-form" class="form form-modal">
        <h2>{state.isNew ? "Add" : "Edit"} Product</h2>

        <Show when={!state.isNew}>
          <nav id="product-edit-tabs" class="tabs">
            <a href="#" class="selected" onClick={switchTab.bind(null, "main")}>Main</a>
            <a href="#" onClick={switchTab.bind(null, "email")}>Email</a>
          </nav>
        </Show>

        <div id="product-edit-main" class="product-edit-tab">
          <p>
            <label>Name</label>
            <input type="text" name="name" maxLength="255" value={state.product.name} placeholder="Product Name" autofocus onInput={autocode} />
          </p>

          <Show when={state.isNew}>
            <p class="form-half-left">
              <label>Code</label>
              <input type="text" name="code" maxLength="255" placeholder="product-name" value={state.product.code} onInput={autocodeConfigure} class="input-with-help" />
              <span class="help">
                <span class="help-icon">?</span>
                <span class="help-text">Unique identifier for the product. Also known as URL slug. Only alphanumeric characters, hyphens, or dashes allowed.</span>
              </span>
            </p>

            <p class="form-half-right">
              <label>Price</label>
              <input type="text" name="price" maxLength="12" placeholder="19.00" class="form-input-money" onInput={onInputMoney} value={money(state.product.price)} />
            </p>

            <p class="clear">
              <label>URL</label>
              <input type="text" name="url" maxLength="2048" placeholder="https://example.com" value={state.product.url || ""} class="input-with-help" />
              <span class="help">
                <span class="help-icon">?</span>
                <span class="help-text">URL of product website where the embedded purchase modal will be installed</span>
              </span>
            </p>

            <p>
              <label>From Email</label>
              <input name="email__from_email" type="text" maxLength="255" placeholder="johndoe@example.com" value={state.product.email.from_email || ""} class="input-with-help" />
              <span class="help">
                <span class="help-icon">?</span>
                <span class="help-text">Receipt emails will come from this address. Must be an Amazon SES verified sender.</span>
              </span>
            </p>

            <p>
              <label>Descriptor</label>
              <input type="text" name="descriptor" maxLength="22" placeholder="Credit card statement description" value={state.product.descriptor} class="input-with-help" />
              <span class="help">
                <span class="help-icon">?</span>
                <span class="help-text">Text shown on your customer's credit card statement. Limited to 22 characters.</span>
              </span>
            </p>

            <p>
              <label>Description <span class="suffix">(optional)</span></label>
              <textarea name="description" placeholder="Product description for customer, as Markdown or HTML">{state.product.description}</textarea>
            </p>
          </Show>
          <Show when={!state.isNew}>
            <p>
              <label>Code</label>
              <input type="text" name="code" maxLength="255" placeholder="product-name" value={state.product.code} class="input-with-help" />
              <span class="help">
                <span class="help-icon">?</span>
                <span class="help-text">Unique identifier for the product. Also known as URL slug. Only alphanumeric characters, hyphens, or dashes allowed.</span>
              </span>
            </p>

            <p>
              <label>URL</label>
              <input type="text" name="url" maxLength="2048" placeholder="https://example.com" value={state.product.url || ""} class="input-with-help" />
              <span class="help">
                <span class="help-icon">?</span>
                <span class="help-text">URL of product website where the embedded purchase modal will be installed</span>
              </span>
            </p>
          </Show>
        </div>

        <Show when={!state.isNew}>
          <div id="product-edit-email" class="product-edit-tab hidden">
            <p>
              <label>Section</label>
              <select onChange={switchSection.bind(null, "email-section")}>
                <option value="addresses" selected>Email Addresses</option>
                <option value="template">Template</option>
                <option value="colors">Colors</option>
                <option value="receipt-email">Receipt Email Content</option>
                <option value="recover-email">Recover Email Content</option>
              </select>
            </p>

            <div class="email-section" id="email-section-addresses">
              <p>
                <label>From Email</label>
                <input name="email__from_email" type="text" maxLength="255" placeholder="johndoe@example.com" value={state.product.email.from_email || ""} class="input-with-help" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Receipt emails will come from this address. Must be an Amazon SES verified sender.</span>
                </span>
              </p>

              <p>
                <label>From Name<span class="suffix">(optional)</span></label>
                <input name="email__from_name" type="text" maxLength="255" placeholder="John Doe" value={state.product.email.from_name || ""} class="input-with-help" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Optional sender display name that's paired with the from email address.</span>
                </span>
              </p>

              <p>
                <label>Reply To Email<span class="suffix">(optional)</span></label>
                <input name="email__reply_to_email" type="text" maxLength="255" placeholder="johndoe@example.com" value={state.product.email.reply_to_email || ""} class="input-with-help" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Email address used for the Reply-To section of customers' receipt emails.</span>
                </span>
              </p>

              <p>
                <label>Reply To Name<span class="suffix">(optional)</span></label>
                <input name="email__reply_to_name" type="text" maxLength="255" placeholder="John Doe" value={state.product.email.reply_to_name || ""} class="input-with-help" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Optional reply display name that's paired with the reply to email address.</span>
                </span>
              </p>
            </div>

            <div class="email-section hidden" id="email-section-template">
              <p>
                <label>Greeting <span class="suffix">(optional)</span></label>
                <textarea name="email__greeting" placeholder="{% if name %} Hi {{name}}, {% else %} Hi, {% endif %}">{state.product.email.greeting}</textarea>
                <span class="help help-label">
                  <span class="help-icon">?</span>
                  <span class="help-text">Text to include at the beginning of both receipt and recover emails, in Markdown or HTML. Leave blank to not include anything. Available placeholders are: {"{{"}name{"}}"}, {"{{"}first_name{"}}"}, and {"{{"}full_name{"}}"}.</span>
                </span>
              </p>

              <p>
                <label>Signature <span class="suffix">(optional)</span></label>
                <textarea name="email__signature" placeholder="Thank you!">{state.product.email.signature}</textarea>
                <span class="help help-label">
                  <span class="help-icon">?</span>
                  <span class="help-text">Text to include at the end of both receipt and recover emails, in Markdown or HTML. Leave blank to not include anything.</span>
                </span>
              </p>

              <p>
                <label>Footer <span class="suffix">(optional)</span></label>
                <textarea name="email__footer" placeholder="Sent from {{product_link | safe}}">{state.product.email.footer}</textarea>
                <span class="help help-label">
                  <span class="help-icon">?</span>
                  <span class="help-text">Text to include in the email's footer, as Markdown or HTML. Links to product website by default. This is a good place for links to social media. Available placeholders are: {"{{"}product_link{"}}"} and {"{{"}separator{"}}"}.</span>
                </span>
              </p>

              <p>
                <label>Company Address <span class="suffix">(optional)</span></label>
                <input name="email__address" type="text" maxLength="1024" placeholder="123 Main St, San Francisco, CA 94115" value={state.product.email.address} class="input-with-help" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Address to include in email's footer. Transactional emails (for receipt/recovery) don't require an address. Newsletter/marketing emails require a physical address due to regulations.</span>
                </span>
              </p>
            </div>

            <div class="email-section hidden" id="email-section-colors">
              <p>
                <label>Theme Color <span class="suffix">(optional)</span></label>
                <input type="text" name="email__primary_color" maxLength="7" value={state.product.email.primary_color} class="form-input-hex" onInput={onInputHex} placeholder="#3f8efa" class="input-with-help" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Hex color used for email's call to action (eg button color). Defaults to blue.</span>
                </span>
              </p>

              <p>
                <label>Link Color <span class="suffix">(optional)</span></label>
                <input type="text" name="email__link_color" maxLength="7" value={state.product.email.link_color} class="form-input-hex" onInput={onInputHex} placeholder="#3f8efa" />
              </p>

              <p>
                <label>Link Hover Color <span class="suffix">(optional)</span></label>
                <input type="text" name="email__link_hover_color" maxLength="7" value={state.product.email.link_hover_color} class="form-input-hex" onInput={onInputHex} placeholder="#4f9ffb" />
              </p>
            </div>

            <div class="email-section hidden" id="email-section-receipt-email">
              <p>
                A receipt email is sent to your customer whenever a purchase is made. It includes a link to download your product. These fields can be left blank to use the default text.
              </p>

              <p>
                <label>Subject <span class="suffix">(optional)</span></label>
                <input name="email__receipt_subject" type="text" maxLength="2048" placeholder="You bought {{product_name}}!" value={state.product.email.receipt_subject} class="input-with-help" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Subject for email. Available placeholders are: {"{{"}product_name{"}}"} and {"{{"}price{"}}"}.</span>
                </span>
              </p>

              <p>
                <label>Body <span class="suffix">(optional)</span></label>
                <textarea name="email__receipt_body" placeholder="Thank you for purchasing {{product_name}} ({{price}}). You can access your order via the button below.">{state.product.email.receipt_body}</textarea>
                <span class="help help-label">
                  <span class="help-icon">?</span>
                  <span class="help-text">Content for email body. Available placeholders are: {"{{"}product_name{"}}"} and {"{{"}price{"}}"}.</span>
                </span>
              </p>

              <p>
                <label>Button Text <span class="suffix">(optional)</span></label>
                <input name="email__receipt_button" type="text" maxLength="255" placeholder="View Order" value={state.product.email.receipt_button} class="input-with-help" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Label for main button. It directs customers to view/download their purchase.</span>
                </span>
              </p>
            </div>

            <div class="email-section hidden" id="email-section-recover-email">
              <p>
                Links to purchases expire after 48 hours. Customers can submit the recovery form, which will send this recovery email and include a new link. These fields can be left blank to use the default text.
              </p>

              <p>
                <label>Subject <span class="suffix">(optional)</span></label>
                <input name="email__recover_subject" type="text" maxLength="2048" placeholder="Link to {{product_name}}" value={state.product.email.recover_subject} class="input-with-text" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Subject for email. Available placeholders are: {"{{"}product_name{"}}"} and {"{{"}price{"}}"}.</span>
                </span>
              </p>

              <p>
                <label>Body <span class="suffix">(optional)</span></label>
                <textarea name="email__recover_body" placeholder="You can access your order of {{product_name}} via the button below.">{state.product.email.recover_body}</textarea>
                <span class="help help-label">
                  <span class="help-icon">?</span>
                  <span class="help-text">Content for email body. Available placeholders are: {"{{"}product_name{"}}"} and {"{{"}price{"}}"}.</span>
                </span>
              </p>

              <p>
                <label>Button Text <span class="suffix">(optional)</span></label>
                <input name="email__recover_button" type="text" maxLength="255" placeholder="View Order" value={state.product.email.recover_button} class="input-with-text" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Label for main button. It directs customers to view/download their purchase again.</span>
                </span>
              </p>
            </div>
          </div>
        </Show>

        <p>
          <input type="submit" onClick={submit} class="btn form-btn-full" value={state.isNew ? "Create" : "Save"} />
        </p>
      </form>
    </Modal>
  );
}
