import Request from "../utils/request";

export async function list(newsletterCode: string, stats : boolean = false) {
  const includes = stats ? "&includes=stats" : "";
  const url = `/api/admin/broadcasts?newsletter_code=${newsletterCode}${includes}`
  const response = await Request.get(url);
  return response.broadcasts;
}

export async function get(uuid: string, stats : boolean = false) {
  const includes = stats ? "?includes=stats" : "";
  const url = `/api/admin/broadcasts/${uuid}${includes}`
  const response = await Request.get(url);
  return response.broadcast;
}

export async function create(json: object) {
  const response = await Request.post(`/api/admin/broadcasts`, json);
  return response.broadcast;
}

export async function update(uuid: string, json: object) {
  const response = await Request.patch(`/api/admin/broadcasts/${uuid}`, json);
  return response.broadcast;
}

export async function send(uuid: string) {
  const response = await Request.patch(`/api/admin/broadcasts/${uuid}/send`);
  return response.broadcast;
}

export async function preview(uuid: string) {
  const response = await Request.post(`/api/admin/broadcasts/${uuid}/preview`);
  return response;
}

export async function destroy(uuid: string) {
  const response = await Request.destroy(`/api/admin/broadcasts/${uuid}`);
  return response.broadcast;
}

export default {
  list,
  get,
  update,
  send,
  preview,
  create,
  destroy
};
