import { onMount } from "solid-js";
import { render } from "solid-js/web";
import icon from "./icon";

interface NotifyOptions {
  icon?: string,
  autoclose?: boolean
}

export function notify(message: string, options: NotifyOptions = {}) {
  if (options.icon === undefined) { options.icon = "bell"; }
  if (options.autoclose === undefined) { options.autoclose = false; }

  render(
    () => <NotifyMessage message={message} icon={options.icon} autoclose={options.autoclose} type="default" />,
    document.getElementById("notify")
  );
}

export function notifySuccess(message: string, options: NotifyOptions = {}) {
  if (options.icon === undefined) { options.icon = "square-check"; }
  if (options.autoclose === undefined) { options.autoclose = false; }

  render(
    () => <NotifyMessage message={message} icon={options.icon} autoclose={options.autoclose} type="success" />,
    document.getElementById("notify")
  );
}

export function notifyError(message: string, options: NotifyOptions = {}) {
  if (options.icon === undefined) { options.icon = "square-alert"; }
  if (options.autoclose === undefined) { options.autoclose = false; }

  render(
    () => <NotifyMessage message={message} icon={options.icon} autoclose={options.autoclose} type="error" />,
    document.getElementById("notify")
  );
}

function NotifyMessage(props) {
  let notifyEl;

  onMount(() => {
    if (props.autoclose) {
      setTimeout(() => { removeNotification() }, 10000);
    }
  });

  function removeNotification(e = null) {
    if (e) { e.preventDefault(); }
    if (!notifyEl) { return; } // in case user clicks close before autoclose fires

    if (!notifyEl.classList.contains("notify-hide")) {
      notifyEl.classList.add("notify-hide");
      setTimeout(() => {
        notifyEl.remove();
      }, 500);
    }
  }

  return (
    <div class={`notify-message notify-${props.type}`} ref={notifyEl}>
      {icon(props.icon)}
      {props.message}
      <a href="#" class="notify-close" onClick={removeNotification}>&times;</a>
    </div>
  );
}
