import { createEffect, createState, onCleanup } from "solid-js";
import { For, Show } from "solid-js/web";
import { Link } from "../components/router";
import { Modal, openModal, closeModal } from "../components/modal";
import DripManager from "../models/drip-manager";
import LoadSpin from "../icons/load-spin";
import ChevronLeft from "../icons/chevron-left";
import ChevronRight from "../icons/chevron-right";
import ChevronDown from "../icons/chevron-down";
import StatsBar from "../icons/stats-bar";
import Menu from "../icons/menu";
import { num, money, date, datetime, status, linkText } from "../utils/format";
import { bindForm, queryInput, queryForm, queryJson, onInputMoney } from "../utils/form";
import Sortable from "sortablejs";

interface Drip {
  uuid: string,
  subject: string,
  body: string,
  recipients: number,
  days: number,
  position: number,
  status: string,
  stats: SendableStats
}

interface SendableStats {
  emails: number,
  soft_bounced: number,
  hard_bounced: number,
  rejected: number,
  complaint: number,
  opened: number,
  clicked: number,
  unsubscribed: number
}

const emptyDrip = {
  uuid: null,
  subject: "",
  body: "",
  recipients: 0,
  days: 7,
  position: 0,
  status: "draft",
  stats: {
    emails: 0,
    soft_bounced: 0,
    hard_bounced: 0,
    rejected: 0,
    complaint: 0,
    opened: 0,
    clicked: 0,
    unsubscribed: 0
  }
};

function EmptyDrips(props) {
  return (
    <div class="empty">
      <img src="/assets/img/drip.svg" />
      <h1>No Drips</h1>
      <p>
        Drips are pre-written email sequences sent to subscribers on a schedule.<br/>
        No drip emails have been created yet.
      </p>
      <p>
        <Link class="btn" href={`/admin/drips/new?newsletter=${props.newsletter.code}`}>Add Drip</Link>
      </p>
    </div>
  );
}

function LoadingDrips() {
  return (
    <div class="loading loading-tab">
      <LoadSpin />
    </div>
  );
}

export default function(props) {
  const [state, setState] = createState({ drips: undefined, sorting: false });
  const page = {
    refreshDrips: (e) => {
      if (e) { e.preventDefault(); }
      DripManager.list(props.newsletter.code, true).then((drips) => {
        setState({ drips });
      });
    },
    submitDrip: (newsletter, dripUuid, e) => {
      e.preventDefault();
      const json = queryJson(
        "#drip-form",
        ["subject", "body"],
        { newsletter_code: props.newsletter.code }
      );

      if (dripUuid) {
        DripManager.update(dripUuid, json).then(() => {
          page.refreshDrips();
          closeModal();
        });
      } else {
        DripManager.create(json).then(() => {
          page.refreshDrips();
          queryForm("#drip-form").reset();
          closeModal();
        });
      }
    },
    toggleSort: (e) => {
      e.preventDefault();
      Sortable.create(
        document.getElementById("drips-table-tbody"), {
          handle: ".table-sort-icon",
          dragClass: "table-sort-drag"
        }
      );
      if (!state.sorting) {
        setState({ sorting: true });
      } else { // save changes
        const rows = document.querySelectorAll("#drips-table-tbody tr");
        const uuids: Array<string> = [];
        rows.forEach((row) => {
          uuids.push(row.getAttribute("data-uuid"));
        });
        DripManager.updatePositions(props.newsletter.code, uuids).then(() => {
          setState({ drips: undefined, sorting: false });
          page.refreshDrips();
        });
      }
    },
    cancelSort: (e) => {
      e.preventDefault();
      setState({ drips: undefined, sorting: false });
      page.refreshDrips();
    }
  };
  page.refreshDrips();

  return (<>
    <Show when={state.drips === undefined}>
      <LoadingDrips />
    </Show>
    <Show when={state.drips && state.drips.length <= 0}>
      <EmptyDrips newsletter={props.newsletter} page={page} />
    </Show>
    <Show when={state.drips && state.drips.length > 0}>
      <h3>Drips <span class="suffix">({ state.drips.length })</span></h3>
      <div class="menu">
        <Show when={!state.sorting}>
          <Show when={state.drips.length > 1}>
            <a href="#" onClick={page.toggleSort}>Sort</a>
            <span class="slash">/</span>
          </Show>
          <Link href={`/admin/drips/new?newsletter=${props.newsletter.code}`}>Add Drip</Link>
        </Show>
        <Show when={state.sorting}>
          <a href="#" onClick={page.toggleSort}>Save Changes</a>
          <span class="slash">/</span>
          <a href="#" onClick={page.cancelSort}>Cancel</a>
        </Show>
      </div>
      <table>
        <thead>
          <tr>
            <th>Subject</th>
            <th class="align-right">Days</th>
            <th class="align-right">Recipients</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody id="drips-table-tbody" class={state.sorting ? "table-sorting" : ""}>
          <For each={state.drips}>
            {(drip: Drip) =>
              <tr data-uuid={drip.uuid}>
                <td>
                  <span class="table-sort-icon"><Menu /></span>
                  <Show when={state.sorting}>
                    {drip.subject}
                  </Show>
                  <Show when={!state.sorting}>
                    <Link href={`/admin/drips/${drip.uuid}`}>{linkText(drip.subject)}</Link>
                  </Show>
                </td>
                <td class="align-right">{num(drip.days)}</td>
                <td class="align-right">
                  {num(drip.recipients)}
                  <span class="help help-details help-details-stats">
                    <span class="help-icon"><StatsBar /></span>
                    <span class="help-text">
                      <Show when={props.newsletter.track === "open" || props.newsletter.track === "all"}>
                        {num(drip.stats.opened)} Opened <br/>
                      </Show>
                      <Show when={props.newsletter.track === "click" || props.newsletter.track === "all"}>
                        {num(drip.stats.clicked)} Clicked <br/>
                      </Show>
                      {num(drip.stats.unsubscribed)} Unsubscribed <br/>
                      {num(drip.stats.soft_bounced)} Soft Bounced <br/>
                      {num(drip.stats.hard_bounced)} Hard Bounced <br/>
                      {num(drip.stats.rejected)} Rejected <br/>
                      {num(drip.stats.complaint)} Complained <br/>
                    </span>
                  </span>
                </td>
                <td>{status(drip.status)}</td>
              </tr>
            }
          </For>
        </tbody>
      </table>
    </Show>
  </>);
}
