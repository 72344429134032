import { createState } from "solid-js";
import { Show, For } from "solid-js/web";
import { Link, navigate } from "../components/router";
import { modalClick } from "../components/modal";
import NewsletterEditModal from "../components/newsletter-edit-modal";
import NewsletterManager from "../models/newsletter-manager";
import { num, linkText } from "../utils/format";
import ChevronDown from "../icons/chevron-down";
import LoadSpin from "../icons/load-spin";

function EmptyNewsletters() {
  return (
    <div class="empty">
      <img src="/assets/img/newsletter.svg" />
      <h1>No Newsletters</h1>
      <p>
        Want to build an audience? Get started here!
      </p>
      <p>
        <a class="btn" href="#" onClick={modalClick("#newsletter-edit-modal")}>Add Newsletter</a>
      </p>
    </div>
  );
}

export default function() {
  const newsletterResponse = NewsletterManager.list(false, true);
  const [state, setState] = createState({ newsletters: null });
  newsletterResponse.then(function(newsletters) {
    setState("newsletters", newsletters);
  });

  return (<>
    <Show when={state.newsletters && state.newsletters.length > 0}>
      <h3>Newsletters</h3>

      <div class="menu">
        <a href="#" onClick={modalClick("#newsletter-edit-modal")}>Add Newsletter</a>
      </div>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Author</th>
            <th class="align-right"># Subscribers</th>
          </tr>
        </thead>
        <tbody>
          <For each={state.newsletters}>
            {newsletter =>
              <tr>
                <td><Link href={`/admin/newsletters/${newsletter.code}`}>{linkText(newsletter.name)}</Link></td>
                <td>{newsletter.email.from_name ? `${newsletter.email.from_name} <${newsletter.email.from_email}>` : newsletter.email.from_email}</td>
                <td class="align-right">{num(newsletter.subscribers_count)}</td>
              </tr>
            }
          </For>
        </tbody>
      </table>
    </Show>

    <Show when={state.newsletters && state.newsletters.length <= 0}>
      <EmptyNewsletters />
    </Show>

    <Show when={state.newsletters === null}>
      <div class="loading">
        <LoadSpin />
      </div>
    </Show>

    <NewsletterEditModal />
  </>);
}
