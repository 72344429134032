import { onMount, createEffect, createState, onCleanup } from "solid-js";
import { For, Show } from "solid-js/web";
import { Link, getQueryValue } from "../components/router";
import { Modal, openModal, closeModal, modalClick } from "../components/modal";
import { IconEditor, getSelectedIcon } from "../components/icon-editor";
import Picture from "../icons/picture";
import Invoice from "../icons/invoice";
import StatsBar from "../icons/stats-bar";
import CloudUpload from "../icons/cloud-upload";
import LoadSpin from "../icons/load-spin";
import currentSession from "../models/current-session";
import AssetManager from "../models/asset-manager";
import { date, linkText } from "../utils/format";
import { SelectMenu, SelectOption, getSelected } from "../components/select-menu";
import { bindForm, queryInput, queryForm, queryJson } from "../utils/form";
import Dropzone from "dropzone";
import icon from "../utils/icon";
import { isPresent, isNullOrUndefined } from "../utils/helper";

const emptyAsset = {
  uuid: null,
  name: "",
  filename: "",
  url: null,
  url_type: "default",
  script: null,
  script_target: null,
  icon: "file"
};
const emptyUrlAsset = Object.assign({}, emptyAsset, {filename: null, url: "", script: null, script_target: null});
const emptyScriptAsset = Object.assign({}, emptyAsset, {filename: null, url: null, script: "", script_target: ""});

interface Option {
  code: string,
  name: string
}

function Loading() {
  return (
    <div class="loading loading-tab">
      <LoadSpin />
    </div>
  );
}

function EmptyAssets(props) {
  return (
    <div class="empty">
      <img src="/assets/img/asset.svg" />
      <h1>No Files or Links</h1>
      <p>
        Assets are delivered to your customers after a purchase. <br />
        They can be a file or a URL link.
      </p>
      <p>
        <a href="#" class="btn" style="margin-right:0.6rem" onClick={modalClick("#upload-modal")}>Upload File</a>
        <a href="#" class="btn btn-gray" onClick={(e) => props.page.editAsset(emptyUrlAsset, e)}>Add Link</a>
      </p>
    </div>
  );
}

function ReplaceModal(props) {
  const [state, setState] = createState({ asset: emptyAsset, isEmpty: true, inProgress: false });
  let dropzone;

  props.page.replaceAsset = (asset, e) => {
    if (e) { e.preventDefault(); }
    setState({ asset });
    closeModal();
    openModal("#asset-replace-modal");
  }

  function getUrl() {
    return `/api/admin/assets/${state.asset.uuid}/file`;
  }

  function onFileChange() {
    if (dropzone.files.length > 1) {
      dropzone.removeFile(dropzone.files[0]);
    }
    const total = document.querySelectorAll("#replace-form .form-dropzone-previews .dz-preview").length;
    setState({
      isEmpty: total <= 0
    });
  }

  function done(e) {
    e.preventDefault();
    dropzone.removeAllFiles(true);
    closeModal();
  }

  function onSelectFile(e) {
    e.preventDefault();
    dropzone.hiddenFileInput.click();
  }

  onMount(() => {
    dropzone = new Dropzone("#replace-form .form-dropzone-well", {
      url: getUrl,
      method: "PATCH",
      autoProcessQueue: true,
      createImageThumbnails: false,
      uploadMultiple: false,
      parallelUploads: 1,
      paramName: "file",
      headers: {
        Authorization: `Bearer ${currentSession.accessToken}`
      },
      ignoreHidenFiles: false,
      addRemoveLinks: false,
      previewsContainer: "#replace-form .form-dropzone-previews",
      clickable: "#replace-form .form-dropzone-click",
      previewTemplate:
        "<div class='dz-preview'>" +
          "<span class='dz-preview-success'>&check;</span>" +
          "<span class='dz-preview-name' data-dz-name></span>" +
          "<span class='dz-preview-size'>(<span data-dz-size></span>)</span>" +
          "<div class='dz-preview-error-msg' data-dz-errormessage></div>" +
          "<div class='dz-preview-upload'><div class='dz-preview-upload-progress' data-dz-uploadprogress></div></div>" +
          "<a class='dz-preview-remove' href='#' data-dz-remove>&times;</a>" +
        "</div>"
    });
    dropzone.on("addedfile", onFileChange);
    dropzone.on("removedfile", onFileChange);
    dropzone.on("sending", () => {
      setState({ inProgress: true });
    });
    dropzone.on("success", (_file, response) => {
      setState({ asset: response.asset });
    });
    dropzone.on("queuecomplete", () => {
      props.page.refreshAssets();
      setState({ inProgress: false });
    });
  });

  return (
    <Modal id="asset-replace-modal" class="modal-long modal-wide">
      <span class="modal-back-arr" onClick={props.page.editAsset.bind(null, state.asset)}>&larr;</span>
      <form id="replace-form" class="form form-modal form-dropzone">
        <div class="form-dropzone-well">
          <p class="form-dropzone-instruct">
            <CloudUpload /> <a href="#" onClick={onSelectFile} class="form-dropzone-click"> Replace file</a>: {state.asset.filename}
          </p>
        </div>
        <div class="form-dropzone-previews dropzone-previews">
        </div>
        <Show when={state.isEmpty}>
          <img src="/assets/img/upload-replace.svg" class="form-dropzone-empty" />
        </Show>
        <Show when={!state.isEmpty}>
          <p>
            <input disabled={state.inProgress} type="submit" class="btn form-btn-full" value="Done" onClick={done} />
          </p>
        </Show>
      </form>
    </Modal>
  );
}

function UploadModal(props) {
  const [state, setState] = createState({ isEmpty: true, inProgress: false, isFinished: true });
  let dropzone;

  function getUrl() {
    const options = getSelected("upload-options").join(",");
    return `/api/admin/assets/files?product_code=${props.product.code}&option_codes=${options}`;
  }

  function onFileChange() {
    const total = document.querySelectorAll("#upload-form .form-dropzone-previews .dz-preview").length;
    const completed = document.querySelectorAll("#upload-form .form-dropzone-previews .dz-preview.dz-complete").length;
    setState({
      isEmpty: total <= 0,
      isFinished: (total-completed) <= 0
    });
  }

  function uploadFiles(e) {
    e.preventDefault();
    dropzone.options.autoProcessQueue = true;
    dropzone.processQueue();
    setState({ inProgress: true });
  }

  function done(e) {
    reset(e);
    closeModal();
  }

  function reset(e) {
    e.preventDefault();
    dropzone.removeAllFiles(true);
  }

  function isSelected(code) {
    if (document.querySelector("#upload-options")) {
      return getSelected("#upload-options").includes(code);
    } else {
      return false;
    }
  }

  onMount(() => {
    dropzone = new Dropzone("#upload-form .form-dropzone-well", {
      url: getUrl,
      autoProcessQueue: false,
      createImageThumbnails: false,
      uploadMultiple: false,
      parallelUploads: 1,
      paramName: "file",
      headers: {
        Authorization: `Bearer ${currentSession.accessToken}`
      },
      ignoreHidenFiles: false,
      addRemoveLinks: false,
      previewsContainer: "#upload-form .form-dropzone-previews",
      clickable: "#upload-form .form-dropzone-click",
      previewTemplate:
        "<div class='dz-preview'>" +
          "<span class='dz-preview-success'>&check;</span>" +
          "<span class='dz-preview-name' data-dz-name></span>" +
          "<span class='dz-preview-size'>(<span data-dz-size></span>)</span>" +
          "<div class='dz-preview-error-msg' data-dz-errormessage></div>" +
          "<div class='dz-preview-upload'><div class='dz-preview-upload-progress' data-dz-uploadprogress></div></div>" +
          "<a class='dz-preview-remove' href='#' data-dz-remove>&times;</a>" +
        "</div>"
    });
    dropzone.on("addedfile", onFileChange);
    dropzone.on("removedfile", onFileChange);
    dropzone.on("queuecomplete", () => {
      const total = document.querySelectorAll("#upload-form .form-dropzone-previews .dz-preview").length;
      const completed = document.querySelectorAll("#upload-form .form-dropzone-previews .dz-preview.dz-complete").length;

      dropzone.options.autoProcessQueue = false;
      setState({
        inProgress: false,
        isFinished: (total-completed) <= 0
      });
      props.page.refreshAssets();
    });
  });

  return (
    <Modal id="upload-modal" class="modal-long modal-wide">
      <form id="upload-form" class="form form-modal form-dropzone">
        <div class="form-dropzone-well">
          <p class="form-dropzone-instruct">
            <CloudUpload /> <a href="#" class="form-dropzone-click">Upload files</a> or drag them here
          </p>
        </div>
        <div class="form-dropzone-previews dropzone-previews">
        </div>
        <Show when={state.isEmpty}>
          <img src="/assets/img/upload.svg" class="form-dropzone-empty" />
        </Show>
        <Show when={!state.isEmpty}>
          <p>
            <label>Attach to Options</label>
            <SelectMenu id="upload-options">
              <For each={props.options}>
                { option => <SelectOption value={(option as Option).code}>{(option as Option).name}</SelectOption> }
              </For>
            </SelectMenu>
          </p>
          <p>
            <Show when={state.isFinished}>
              <input type="submit" class="btn form-btn-half" value="Done" onClick={done} />
            </Show>
            <Show when={!state.isFinished}>
              <input disabled={state.inProgress} type="submit" class="btn form-btn-half" value="Upload Files" onClick={uploadFiles} />
            </Show>
            <input type="submit" class="btn btn-gray form-btn-half" value="Reset" onClick={reset} />
          </p>
        </Show>
      </form>
    </Modal>
  );
}

function DeleteModal(props) {
  const [state, setState] = createState({ asset: emptyAsset });

  props.page.deleteAsset = (asset, e) => {
    e.preventDefault();
    setState({ asset });
    closeModal();
    openModal("#asset-delete-modal");
  };

  function destroy(asset, e) {
    e.preventDefault();
    AssetManager.destroy(asset.uuid).then(() => {
      props.page.refreshAssets();
      closeModal();
    });
  }

  return (
    <Modal id="asset-delete-modal">
      <div class="form">
        <h2>Delete Asset</h2>
        <p>
          Are you sure you want to delete <b>{ state.asset.name }</b>? 
          Existing purchases for this asset will also be removed. Customers will 
          no longer have access to it.
        </p>
        <p>
          <a href="#" class="btn btn-red form-btn-half" onClick={destroy.bind(null, state.asset)}>Confirm Deletion</a>
          <a href="#" class="btn btn-gray modal-close" onClick={closeModal}>Cancel</a>
        </p>
      </div>
    </Modal>
  );
}

interface Asset {
  id: number,
  name: string
}

function EditModal(props) {
  const [state, setState] = createState({ asset: emptyAsset, isNew: true, isUrl: false, isScript: false });

  props.page.editAsset = (asset, e) => {
    if (e) { e.preventDefault(); }
    setState({
      asset,
      isNew: !asset.uuid,
      isUrl: isPresent(asset.url) || (!asset.uuid && !isNullOrUndefined(asset.url)),
      isScript: isPresent(asset.script) || (!asset.uuid && !isNullOrUndefined(asset.script))
    });
    closeModal();
    openModal("#asset-edit-modal");
  };

  createEffect((uuid = null) => {
    return bindForm("#asset-form", state.asset, uuid, ["url_type"]);
  });

  function submit(asset, e) {
    e.preventDefault();
    const fields = state.isUrl ? ["name", "url", "url_type"] : (state.isScript ? ["name", "script", "script_target"] : ["name", "filename"]);
    const json = queryJson("#asset-form", fields, {
      icon: getSelectedIcon()
    });

    let response;
    if (asset.uuid) {
      response = AssetManager.update(asset.uuid, json);
    } else {
      response = AssetManager.create(props.product.code, [], json);
    }
    response.then(() => {
      props.page.refreshAssets();
      queryForm("#asset-form").reset();
      closeModal();
    });
  }

  return (
    <Modal id="asset-edit-modal">
      <form id="asset-form" class="form form-modal">
        <h2>
          {state.isNew ? "Add " : "Edit " }
          {state.isUrl ? "Link" : (state.isScript ? "Script" : "File")}
        </h2>
        <p>
          <label>Name</label>
          <input type="text" name="name" maxLength="255" placeholder="Name for Customer" value={state.asset.name} autofocus class="input-with-help" />
          <span class="help">
            <span class="help-icon">?</span>
            <span class="help-text">{state.isUrl ? "Link" : "Download"}'s text label for customers.</span>
          </span>
        </p>
        <p class={state.isUrl ? "" : "hidden"}>
          <label>URL</label>
          <input type="text" name="url" placeholder="https://example.com" value={state.asset.url || ""} class="input-with-help" />
          <span class="help">
            <span class="help-icon">?</span>
            <span class="help-text">Where to redirect customer after they click on link to download.</span>
          </span>
        </p>
        <p class={state.isUrl ? "" : "hidden"}>
          <label>Link Type</label>
          <select name="url_type">
            <option value="default">Default</option>
            <option value="download">Download</option>
            <option value="new">New Tab</option>
          </select>
          <span class="help help-label">
            <span class="help-icon">?</span>
            <span class="help-text">By default, this link will try to open up in the same browser. Select "Download" to treat it as a file download. Select "New Tab" to open this link in a new tab.</span>
          </span>
        </p>
        <p class={state.isScript ? "" : "hidden"}>
          <label>Script</label>
          <input type="text" name="script" placeholder="/path/to/script {{target}}" value={state.asset.script || ""} class="input-with-help" />
          <span class="help">
            <span class="help-icon">?</span>
            <span class="help-text">Command to run to generate customer's file download. Available placeholders are: {"{{"}email{"}}"}, {"{{"}name{"}}"}, {"{{"}uuid{"}}"}, and {"{{"}target{"}}"}. Command should generate a new file at the target absolute path.</span>
          </span>
        </p>
        <p class={state.isScript ? "" : "hidden"}>
          <label>Target Filename</label>
          <input type="text" name="script_target" maxLength="2048" placeholder="downloads.zip" value={state.asset.script_target || ""} class="input-with-help" />
          <span class="help">
            <span class="help-icon">?</span>
            <span class="help-text">The filename customers will download to their own computer.</span>
          </span>
        </p>
        <p class={state.isUrl || state.isScript ? "hidden" : ""}>
          <label>Filename</label>
          <input type="text" name="filename" maxLength="2048" placeholder="downloads.zip" value={state.asset.filename} class="input-with-help" />
          <span class="help">
            <span class="help-icon">?</span>
            <span class="help-text">The filename customers will download to their own computer.</span>
          </span>
          <a href="#" onClick={props.page.replaceAsset.bind(null, state.asset)}>Replace File</a>
        </p>
        <p>
          <label>Select Icon</label>
          <IconEditor uuid={state.asset.uuid} icon={state.asset.icon} />
        </p>
        <p>
          <input type="submit" class={`btn form-btn-${state.isNew ? "full" : "half"}`} onClick={submit.bind(null, state.asset)} value="Save Changes" />
          <Show when={!state.isNew}>
            <input type="submit" class="btn btn-gray form-btn-half" value="Delete" onClick={props.page.deleteAsset.bind(null, state.asset)} />
          </Show>
        </p>
      </form>
    </Modal>
  );
}

export default function(props) {
  const [state, setState] = createState({ assets: undefined, options: [] });
  const showUpload = !!getQueryValue("upload");
  let showAsset = getQueryValue("asset");

  const page = {
    refreshAssets: () => {
      AssetManager.list(props.product.code, true).then((response) => {
        setState({ assets: response.assets, options: response.options });
        if (showAsset) {
          const asset = response.assets.find(asset => asset.uuid === showAsset);
          if (asset) {
            if (asset.filename) {
              page.replaceAsset(asset);
            } else {
              page.editAsset(asset);
            }
            showAsset = null;
          }
        }
      });
    },

    editAsset: Function.prototype,
    deleteAsset: Function.prototype,
    replaceAsset: Function.prototype
  };
  page.refreshAssets();

  onMount(() => {
    if (showUpload) {
      openModal("#upload-modal");
    }
  });

  return (<>
    <Show when={state.assets !== undefined && state.assets.length > 0}>
      <h3>Assets</h3>
      <div class="menu">
        <a href="#" onClick={modalClick("#upload-modal")}>Add File</a>
        <span class="slash">/</span>
        <a href="#" onClick={(e) => page.editAsset(emptyUrlAsset, e)}>Add Link</a>
        <span class="slash">/</span>
        <a href="#" onClick={(e) => page.editAsset(emptyScriptAsset, e)}>Add Script</a>
      </div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th class="table-col-assets">Icon</th>
            <th>Uploaded On</th>
          </tr>
        </thead>
        <tbody>
          <For each={state.assets}>
            {asset =>
              <tr>
                <td>
                  <a href="#" onClick={page.editAsset.bind(null, asset)}>{linkText(asset.name)}</a>
                  <Show when={asset.filename && asset.filename !== asset.name}>
                    <span class="suffix">({asset.filename})</span>
                  </Show>
                  <Show when={!asset.filename && asset.url && asset.url !== asset.name}>
                    <span class="suffix">({asset.url})</span>
                  </Show>
                  <Show when={
                    !asset.filename && !asset.url && asset.script && asset.name !== asset.script
                  }>
                    <span class="suffix">({asset.script.trim().split(" ")[0]})</span>
                  </Show>
                </td>
                <td class="table-col-assets">
                  <span class="table-assets">
                    {icon(asset.icon)}
                  </span>
                </td>
                <td>{date(asset.uploaded_at)}</td>
              </tr>
            }
          </For>
        </tbody>
      </table>
    </Show>
    <Show when={state.assets !== undefined && state.assets.length === 0}>
      <EmptyAssets page={page} />
    </Show>
    <Show when={state.assets === undefined}>
      <Loading />
    </Show>

    <UploadModal page={page} product={props.product} options={state.options} />
    <ReplaceModal page={page} />
    <EditModal page={page} product={props.product} />
    <DeleteModal page={page} />
  </>);
}
