import Request from "../utils/request";

export async function list(productCode: string, includeStats: boolean = false, includeAssets: boolean = false) {
  let includes = "";
  if (includeStats && includeAssets) {
    includes = "&includes=stats,assets";
  } else if (includeStats) {
    includes = "&includes=stats";
  } else if (includeAssets) {
    includes = "&includes=assets";
  }
  const url = `/api/admin/options?product_code=${productCode}${includes}`;
  const response = await Request.get(url);
  return response;
}

export async function create(json: object) {
  const response = await Request.post(`/api/admin/options`, json);
  return response.option;
}

export async function update(uuid: string, json: object) {
  const response = await Request.patch(`/api/admin/options/${uuid}`, json);
  return response.option;
}

export async function updatePositions(productCode: string, uuids: Array<string>) {
  const json = { product_code: productCode, uuids };
  const response = await Request.patch(`/api/admin/options/positions`, json);
  return response.options;
}

export async function destroy(uuid: string) {
  const response = await Request.destroy(`/api/admin/options/${uuid}`);
  return response.option;
}

export default {
  list,
  update,
  updatePositions,
  create,
  destroy
};
