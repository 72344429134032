interface CurrentSession {
  _subscribers: Array<SubscribeFn>,
  accessToken: string | null,
  loggedIn: boolean,
  set(token: string | null): void,
  listen(subscribeFn: SubscribeFn): () => void
}

interface SubscribeFn {
  (this: void, session: CurrentSession): void
}

const currentSession : CurrentSession = {
  _subscribers: [],
  accessToken: null,

  get loggedIn() {
    return !!this.accessToken;
  },

  set: function(token: string | null) {
    this.accessToken = token;
    for (const subscribe of this._subscribers) {
      subscribe(this);
    }
  },

  listen: function(subscribeFn: SubscribeFn) {
    this._subscribers.push(subscribeFn);
    const index = this._subscribers.length - 1;
    return () => {
      this._subscribers.splice(index, 1);
    };
  }
};

export default currentSession;
