import { createEffect, createState, onCleanup } from "solid-js";
import { For, Show } from "solid-js/web";
import { Link } from "../components/router";
import { Modal, openModal, closeModal } from "../components/modal";
import PurchaseManager from "../models/purchase-manager";
import ChevronLeft from "../icons/chevron-left";
import ChevronRight from "../icons/chevron-right";
import ChevronDown from "../icons/chevron-down";
import LoadSpin from "../icons/load-spin";
import { money, date, linkText } from "../utils/format";
import { bindForm, queryInput, queryForm, queryJson, onInputMoney } from "../utils/form";

let refreshPurchases;
let setPurchaseModal;
let setPurchaseDeleteModal;

const emptyPurchase = {
  uuid: null,
  amount: null,
  user: {
    email: "",
    full_name: ""
  },
  option: {
    code: "",
    name: ""
  }
};

function Loading() {
  return (
    <div class="loading loading-tab">
      <LoadSpin />
    </div>
  );
}

function EmptyPurchases() {
  return (
    <div class="empty">
      <img src="/assets/img/purchase.svg" />
      <h1>No Purchases</h1>
      <p>
        No customers have purchased this product yet.
      </p>
      <p>
        <a class="btn" href="#" onClick={editPurchase.bind(null, emptyPurchase)}>Add Purchase</a>
      </p>
    </div>
  );
}

function submitPurchase(product, purchaseUuid, e) {
	e.preventDefault();
  const json = queryJson("#purchase-form", ["full_name", "email", "amount", "option_code"], {
    product_code: product.code
  });

  if (purchaseUuid) {
    PurchaseManager.update(purchaseUuid, json).then(() => {
      refreshPurchases(1, null, true);
      closeModal();
    });
  } else {
    PurchaseManager.create(json).then(() => {
      refreshPurchases(1, null, true);
      queryForm("#purchase-form").reset();
      closeModal();
    });
  }
}

function editPurchase(purchase, e) {
  e.preventDefault();
  setPurchaseModal(purchase);
  openModal("#purchase-edit-modal");
  queryInput("#purchase-form [name=email]").focus();
}

function confirmDeletePurchase(purchase, e) {
  e.preventDefault();
  setPurchaseDeleteModal(purchase);
  closeModal();
  openModal("#purchase-delete-modal");
}

function deletePurchase(purchase, e) {
  e.preventDefault();
  PurchaseManager.destroy(purchase.uuid).then(() => {
    refreshPurchases();
    closeModal();
  });
}

interface Option {
  name: string,
  code: string
}

function EditModal(props) {
  const [state, setState] = createState({ isNew: true, purchase: emptyPurchase });
  setPurchaseModal = (purchase) => { setState({ isNew: !purchase.uuid, purchase }) };

  createEffect((uuid) => {
    return bindForm("#purchase-form", state.purchase, uuid, [
      { name: "option_code", value: state.purchase.option.code }
    ]);
  });

  return (
    <Modal id="purchase-edit-modal">
      <form id="purchase-form" class="form form-modal">
        <h2>{state.isNew ? "Add" : "Edit"} Purchase</h2>
        <p>
          <label>Email Address</label>
          <input type="text" name="email" maxLength="255" placeholder="johndoe@example.com" value={state.purchase.user.email} />
        </p>
        <p>
          <label>Full Name</label>
          <input type="text" name="full_name" maxLength="255" placeholder="John Doe" value={state.purchase.user.full_name} />
        </p>
        <p>
          <label>Amount</label>
          <input type="text" name="amount" maxLength="12" placeholder="Amount" class="form-input-money" value={money(state.purchase.amount)} onInput={onInputMoney} />
        </p>
        <p>
          <label>Option</label>
          <select name="option_code" id="purchase-option-select">
            <For each={props.options}>
              {option =>
                <option value={(option as Option).code}>{(option as Option).name}</option>
              }
            </For>
          </select>
        </p>
        <Show when={state.isNew}>
          <p>
            <input type="submit" class="btn form-btn-full" onClick={submitPurchase.bind(null, props.product, null)} value="Create Purchase" />
          </p>
        </Show>
        <Show when={!state.isNew}>
          <p>
            <input type="submit" class="btn form-btn-half" onClick={submitPurchase.bind(null, props.product, state.purchase.uuid)} value="Update Purchase" />
            <input type="submit" class="btn btn-gray form-btn-half" value="Delete" onClick={confirmDeletePurchase.bind(null, state.purchase)} />
          </p>
        </Show>
      </form>
    </Modal>
  );
}

function DeleteModal(props) {
  const [state, setState] = createState({ purchase: emptyPurchase });
  setPurchaseDeleteModal = (purchase) => { setState({ purchase }) };

  return (
    <Modal id="purchase-delete-modal">
      <div class="form">
        <h2>Delete Purchase</h2>
        <p>
          Are you sure you want to remove <b>{ state.purchase.user.email }</b>? 
          This customer will no longer have access to { props.product.name }.
        </p>
        <p>
          <a href="#" class="btn btn-red form-btn-half" onClick={deletePurchase.bind(null, state.purchase)}>Confirm Deletion</a>
          <a href="#" class="btn btn-gray modal-close" onClick={closeModal}>Cancel</a>
        </p>
      </div>
    </Modal>
  );
}

export default function(props) {
  const [state, setState] = createState({
    purchases: undefined,
    options: [],
    total: 0,
    page: 1,
    pagesTotal: 1
  });
  refreshPurchases = (page : number = 1, e = null, onUpdate = false) => {
    if (e) { e.preventDefault(); }
    PurchaseManager.list(props.product.code, page, true).then((response) => {
      setState({
        purchases: response.purchases,
        options: response.options,
        total: response.total,
        page: response.page,
        pagesTotal: response.pages_total
      });
    });
    if (props.onUpdate && onUpdate) {
      props.onUpdate();
    }
  }
  refreshPurchases();

  return (<>
    <Show when={state.purchases !== undefined && state.purchases.length > 0}>
      <h3>Purchases <span class="suffix">({ state.total })</span></h3>
      <div class="menu">
        <Show when={state.pagesTotal > 1}>
          <Show when={state.page > 1}>
            <a href="#" onClick={refreshPurchases.bind(null, state.page - 1)}><ChevronLeft /> Prev</a>
          </Show>
          <span class="menu-paginate">{state.page} of {state.pagesTotal}</span>
          <Show when={state.page < state.pagesTotal}>
            <a href="#" onClick={refreshPurchases.bind(null, state.page + 1)}>Next <ChevronRight /></a>
          </Show>
          <span class="slash">/</span>
        </Show>
        <a href="#" onClick={editPurchase.bind(null, emptyPurchase)}>Add Purchase</a>
      </div>
      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Name</th>
            <th class="align-right">Amount</th>
            <th>Option</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <For each={state.purchases}>
            {purchase =>
              <tr>
                <td><a href="#" onClick={editPurchase.bind(null, purchase)}>{linkText(purchase.user.email)}</a></td>
                <td>{purchase.user.full_name || purchase.user.first_name}</td>
                <td class="align-right">{money(purchase.amount)}</td>
                <td>{purchase.option.name}</td>
                <td>{date(purchase.created_at)}</td>
              </tr>
            }
          </For>
        </tbody>
      </table>
    </Show>
    <Show when={state.purchases !== undefined && state.purchases.length <= 0}>
      <EmptyPurchases />
    </Show>
    <Show when={state.purchases === undefined}>
      <Loading />
    </Show>
    <EditModal product={props.product} options={state.options} />
    <DeleteModal product={props.product} />
  </>);
}
