import { createState } from "solid-js";
import { For, Show } from "solid-js/web";
import LoadSpin from "../icons/load-spin";
import OptionManager from "../models/option-manager";
import EnvManager from "../models/env-manager";

function Loading() {
  return (
    <div class="loading loading-tab">
      <LoadSpin />
    </div>
  );
}

export default function(props) {
  const envResponse = EnvManager.get();
  const optionResponse = OptionManager.list(props.product.code, true, true);
  const [state, setState] = createState({ env: undefined, options: [] });
  Promise.all([envResponse, optionResponse]).then(([env, optionResponse]) => {
    setState({ env: env, options: optionResponse.options });
  });

  return (<>
    <Show when={state.env == undefined}>
      <Loading />
    </Show>

    <Show when={state.env}>
      <h3>Install</h3>

      <div class="docs">
        <p>
          Add this line right before the <code>&lt;/body&gt;</code> closing tag of your website:
        </p>

        <pre><code>
          <span class="docs-tag">&lt;script</span> <span class="docs-id">src</span>=<span class="docs-string">"{state.env.url}/boutique.js"</span><span class="docs-tag">&gt;&lt;/script&gt;</span>
        </code></pre>

        <p>
          Using a bundler? Download the script and add it to your project:
        </p>

        <pre><code>
          curl {state.env.url}/boutique.js &gt; boutique.js
        </code></pre>

        <p>
          To start a purchase flow, use <code>Boutique.buy(<span class="docs-string">"{props.product.code}"</span>)</code>. For example:
        </p>

        <pre><code>
          <span class="docs-tag">&lt;button</span> <span class="docs-id">onclick</span>=<span class="docs-string">"Boutique.buy('{props.product.code}');"</span><span class="docs-tag">&gt;</span>Buy Now<span class="docs-tag">&lt;/button&gt;</span>
        </code></pre>

        <Show when={state.options.length > 1}>
          <p>
            You can specify a default option with the <code>option</code> setting. 
            You can also remove the options dropdown entirely by passing the <code>single</code> setting:
          </p>

          <pre><code>
            Boutique.buy(<span class="docs-string">"{props.product.code}"</span>); <span class="docs-comment">{'//'} launch modal</span><br />
            <For each={state.options}>
              { option =>
                <><br />
                  Boutique.buy(<span class="docs-string">"{props.product.code}"</span>, {'{'} option: <span class="docs-string">"{option.code}"</span> {'}'}); <span class="docs-comment">{'//'} default select {option.name}</span><br />
                  Boutique.buy(<span class="docs-string">"{props.product.code}"</span>, {'{'} option: <span class="docs-string">"{option.code}"</span>, single: true {'}'}); <span class="docs-comment">{'//'} removes options dropdown</span><br />
                </>
              }
            </For>
          </code></pre>
        </Show>

        <p>
          Customers who lost their download link can have it emailed again. To start a recovery flow, use <code>Boutique.recover(<span class="docs-string">"{props.product.code}"</span>)</code>. For example:
        </p>

        <pre><code>
          <span class="docs-tag">&lt;button</span> <span class="docs-id">onclick</span>=<span class="docs-string">"Boutique.recover('{props.product.code}');"</span><span class="docs-tag">&gt;</span>Already Purchased?<span class="docs-tag">&lt;/button&gt;</span>
        </code></pre>
      </div>
    </Show>
  </>);
}
