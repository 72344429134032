import { money } from "./format";

function selectNumbers(str: string | undefined) {
  if (!str) { return null; }
  const numberStr = (str.match(/\d+/g) || []).join("");
  return numberStr === "" ? null : Number(numberStr);
}

function selectHex(str: string | undefined) {
  if (!str) { return null; }
  const hexStr = (str.match(/[0-9a-fA-F]/g) || []).join("").toLowerCase().slice(0,6);
  return `#${hexStr}`;
}

export function queryForm(selector: string) {
  return (document.querySelector(selector) as HTMLFormElement);
}

export function queryInput(selector: string) {
  return (document.querySelector(selector) as HTMLInputElement);
}

export function queryJson(selector: string, names: Array<string>, fields: object = {}) {
  const json = {};
  names.forEach((name) => {
    const input = queryInput(`${selector} [name=${name}]`);
    if (input) {
      var value;
      if (input.classList.contains("form-input-money") || input.classList.contains("form-input-num")) {
        value = selectNumbers(input.value); 
      } else if (input.classList.contains("form-input-hex")) {
        value = selectHex(input.value);
      } else if (input.type === "checkbox") {
        value = !!input.checked;
      } else {
        value = input.value;
      }

      if (name.match(/__/)) {
        const parts = name.split("__", 2);
        json[parts[0]] = json[parts[0]] || {};
        json[parts[0]][parts[1]] = value;
      } else {
        json[name] = value;
      }
    }
  });
  return Object.assign(json, fields, {});
}

export function onInputNum(e) {
  e.target.value = selectNumbers(e.target.value);
}

export function onInputMoney(e) {
  e.target.value = money(selectNumbers(e.target.value));
}

export function onInputHex(e) {
  e.target.value = selectHex(e.target.value);
}

export function bindForm(
  selector: string,
  model: object,
  uuid: string | undefined | any, // any is here to satisfy tsc
  fields: Array<string | object>,
  callback = Function.prototype
) {
  if (uuid !== model["uuid"]) {
    fields.forEach((field) => {
      let name, value;
      if (typeof(field) === "string") {
        name = field;
        value = model[field];
      } else {
        name = field["name"];
        value = field["value"];
      }
      setTimeout(() => {
        const el = document.querySelector(`${selector} [name=${name}]`);
        if (el) {
          (el as HTMLInputElement).value = value;
        }
      }, 1);
    });
    callback();
  }
  return model["uuid"];
}

export function times() {
  return [
    ["00:00:00", "12:00 AM"],
    ["00:30:00", "12:30 AM"],
    ["01:00:00", "01:00 AM"],
    ["01:30:00", "01:30 AM"],
    ["02:00:00", "02:00 AM"],
    ["02:30:00", "02:30 AM"],
    ["03:00:00", "03:00 AM"],
    ["03:30:00", "03:30 AM"],
    ["04:00:00", "04:00 AM"],
    ["04:30:00", "04:30 AM"],
    ["05:00:00", "05:00 AM"],
    ["05:30:00", "05:30 AM"],
    ["06:00:00", "06:00 AM"],
    ["06:30:00", "06:30 AM"],
    ["07:00:00", "07:00 AM"],
    ["07:30:00", "07:30 AM"],
    ["08:00:00", "08:00 AM"],
    ["08:30:00", "08:30 AM"],
    ["09:00:00", "09:00 AM"],
    ["09:30:00", "09:30 AM"],
    ["10:00:00", "10:00 AM"],
    ["10:30:00", "10:30 AM"],
    ["11:00:00", "11:00 AM"],
    ["11:30:00", "11:30 AM"],
    ["12:00:00", "12:00 PM"],
    ["12:30:00", "12:30 PM"],
    ["13:00:00", "01:00 PM"],
    ["13:30:00", "01:30 PM"],
    ["14:00:00", "02:00 PM"],
    ["14:30:00", "02:30 PM"],
    ["15:00:00", "03:00 PM"],
    ["15:30:00", "03:30 PM"],
    ["16:00:00", "04:00 PM"],
    ["16:30:00", "04:30 PM"],
    ["17:00:00", "05:00 PM"],
    ["17:30:00", "05:30 PM"],
    ["18:00:00", "06:00 PM"],
    ["18:30:00", "06:30 PM"],
    ["19:00:00", "07:00 PM"],
    ["19:30:00", "07:30 PM"],
    ["20:00:00", "08:00 PM"],
    ["20:30:00", "08:30 PM"],
    ["21:00:00", "09:00 PM"],
    ["21:30:00", "09:30 PM"],
    ["22:00:00", "10:00 PM"],
    ["22:30:00", "10:30 PM"],
    ["23:00:00", "11:00 PM"],
    ["23:30:00", "11:30 PM"],
    ["23:59:59", "12:00 AM"]
  ];
}
