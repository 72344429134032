import "./css/all.css";

import Main from "./pages/main";
import Session from "./models/session";
import { navigate } from "./components/router";
import { render } from "solid-js/web";

if (window.location.pathname === "/") {
  navigate("/admin");
}

render(
  () => <Main />,
  document.body
);
