import Request from "../utils/request";

export async function list(newsletterCode: string, stats : boolean = false) {
  const includes = stats ? "&includes=stats" : "";
  const url = `/api/admin/drips?newsletter_code=${newsletterCode}${includes}`
  const response = await Request.get(url);
  return response.drips;
}

export async function get(uuid: string, stats : boolean = false) {
  const includes = stats ? "?includes=stats" : "";
  const url = `/api/admin/drips/${uuid}${includes}`;
  const response = await Request.get(url);
  return response.drip;
}

export async function create(json: object) {
  const response = await Request.post(`/api/admin/drips`, json);
  return response.drip;
}

export async function update(uuid: string, json: object) {
  const response = await Request.patch(`/api/admin/drips/${uuid}`, json);
  return response.drip;
}

export async function updatePositions(newsletterCode: string, uuids: Array<string>) {
  const json = { newsletter_code: newsletterCode, uuids };
  const response = await Request.patch(`/api/admin/drips/positions`, json);
  return response.options;
}

export async function preview(uuid: string) {
  const response = await Request.post(`/api/admin/drips/${uuid}/preview`);
  return response;
}

export async function destroy(uuid: string) {
  const response = await Request.destroy(`/api/admin/drips/${uuid}`);
  return response.drip;
}

export default {
  list,
  get,
  update,
  updatePositions,
  create,
  preview,
  destroy
};
