import { createEffect, createState } from "solid-js";
import { For, Show } from "solid-js/web";
import { Menu, menuClick } from "../components/menu";
import ChevronDown from "../icons/chevron-down";
import icon from "../utils/icon";

interface StatOption {
  icon: string,
  key: string,
  value: string,
  label: string
}

interface StatProps {
  index: number,
  key: string,
  lsKey: string,
  options: Array<StatOption>
}

interface StatsProps {
  options: Array<StatOption>,
  defaults: Array<string>,
  lsKey: string,
  wide: boolean
}

/* TODO: refactor getDefaults and setDefaults! */
function getDefaults(lsKey: string, defaults: Array<string>, options: Array<string>): Array<string> {
  try {
    const defaultsStr = window.localStorage.getItem(lsKey);
    if (defaultsStr) {
      const storedDefaults = JSON.parse(defaultsStr);
      storedDefaults.forEach((val, index) => {
        if (!val || !options.includes(val)) {
          storedDefaults[index] = defaults[index];
        }
      });
      return storedDefaults;
    } else {
      return defaults;
    }
  } catch {
    window.localStorage.removeItem(lsKey);
    return defaults;
  }
}

function setDefault(lsKey: string, index: number, optionKey: string) {
  try {
    const defaults = JSON.parse(window.localStorage.getItem(lsKey) || "[null,null,null,null]");
    defaults[index] = optionKey;
    window.localStorage.setItem(lsKey, JSON.stringify(defaults));
  } catch {
    window.localStorage.removeItem(lsKey);
  }
}

function Stat(props: StatProps) {
  const option = props.options.find(opt => opt.key === props.key) || props.options[0];
  const [state, setState] = createState({ option });

  function switchOption(e, key: string) {
    e.preventDefault();
    if (key === state.option.key) { return; }
    const option = props.options.find(opt => opt.key === key);
    setState({ option });
    setDefault(props.lsKey, props.index, key);
  }

  /* TODO: fix setting new props should update stats (eg adding purchase on product page)
  createEffect(() => {
    const newOption = props.options.find(opt => opt.key === props.key);
    if (newOption && newOption.value !== state.option.value) {
      setState({ option: newOption });
    }
  });
  */

  // TODO(maybe): don't show option if no stat exists for it
  return (
    <div class="stat">
      {icon(state.option.icon)}
      <b class="stat-value">{state.option.value}</b>
      <span
        class="stat-label stat-label-with-menu"
        onClick={menuClick(`#stat-menu-${props.index}`)}
      >
        {state.option.label} <ChevronDown />
      </span>
      <Menu id={`stat-menu-${props.index}`}>
        <For each={props.options}>
          {opt =>
            <li>
              <a href="#" onClick={(e) => switchOption(e, opt.key)}>
                <span class="btn-menu-check">
                  <Show when={opt.key === state.option.key}><span>&check;</span></Show>
                </span>
                {opt.label}
              </a>
            </li>
          }
        </For>
      </Menu>
    </div>
  );
}

export default function(props: StatsProps) {
  return (
    <div class={`stats clear ${props.wide ? "stats-wide" : ""}`}>
      <For each={getDefaults(props.lsKey, props.defaults, props.options.map(opt => opt.key))}>
        {(key, index) => 
          <Stat index={index()} lsKey={props.lsKey} key={key} options={props.options} />
         }
      </For>
    </div>
  );
}
