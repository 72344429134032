import { onMount, createEffect, createState } from "solid-js";
import { For, Show } from "solid-js/web";
import { Modal, modalClick, closeModal } from "../components/modal";
import { navigate } from "../components/router";
import NewsletterManager from "../models/newsletter-manager";
import { SelectMenu, SelectOption, getSelected, setSelected } from "../components/select-menu";
import { bindForm, queryJson, onInputHex, times } from "../utils/form";
import { money, nameCode } from "../utils/format";

export default function(props) {
  const emptyNewsletter = {
    name: "",
    code: null,
    url: "",
    name_type: "none",
    track: "none",
    description: "",
    days: [1,2,3,4,5],
    start_time: "10:00:00",
    end_time: "16:00:00",
    email: {}
  };
  const newsletter = props.newsletter || emptyNewsletter;
  const [state, setState] = createState({ newsletter, isNew: !newsletter.code });

  function submit(e) {
    e.preventDefault();
    const fields = {};
    if (!state.isNew) {
      fields.days = getSelected("newsletter-days") || [];
    }
    const json = queryJson(
      "#newsletter-edit-form",
      state.isNew ?
        ["name", "code", "url", "description", "name_type", "email__from_email"] :
        ["name", "code", "url", "description", "name_type", "track", "start_time", "end_time",
         "email__from_email", "email__from_name", "email__reply_to_email", "email__reply_to_name",
         "email__primary_color", "email__link_color", "email__link_hover_color",
         "email__greeting", "email__signature", "email__footer", "email__address",
         "email__subscribe_subject", "email__subscribe_body", "email__subscribe_button"],
      fields
    );

    const newsletterResponse = state.isNew ?
      NewsletterManager.create(json) :
      NewsletterManager.update(state.newsletter.code, json);
    newsletterResponse.then(function(newsletter) {
      closeModal();
      if (!state.isNew && newsletter.code !== props.newsletter.code) {
        navigate(`/admin/newsletters/${newsletter.code}`);
      } else if (state.isNew) {
        navigate(`/admin/newsletters/${newsletter.code}/drips`);
      } else if (props.onSave) {
        props.onSave(newsletter);
      }
    });
  }

  function switchTab(tab, e) {
    e.preventDefault();
    document.querySelectorAll(".newsletter-edit-tab").forEach((tab) => {
      tab.classList.add("hidden");
    });
    document.getElementById(`newsletter-edit-${tab}`).classList.remove("hidden");

    document.querySelectorAll("#newsletter-edit-tabs a.selected").forEach((anchor) => {
      anchor.classList.remove("selected");
    });
    e.target.classList.add("selected");
  }

  function switchSection(prefix, e) {
    e.preventDefault();
    document.querySelectorAll(`.${prefix}`).forEach((tab) => {
      tab.classList.add("hidden");
    });
    document.getElementById(`${prefix}-${e.target.value}`).classList.remove("hidden");
  }

  function autocode(e) {
    var field = document.querySelector("#newsletter-edit-form [name=code]");
    if (state.isNew && !autocode.off) {
      field.value = nameCode(e.target.value);
    }
  }

  function autocodeConfigure(e) {
    var code = e.target.value;
    var name = document.querySelector("#newsletter-edit-form [name=name]").value;
    autocode.off = nameCode(name) !== code;
    e.target.value = nameCode(code);
  }

  createEffect((uuid = null) => {
    return bindForm(
      "#newsletter-edit-form",
      state.newsletter,
      uuid,
      ["name_type", "start_time", "end_time", "track"]
      // no need to set newsletter-days since multiple modals don't exist on same page
    );
  });

  onMount(() => {
    setSelected("newsletter-days", state.newsletter.days);
  });

  return (
    <Modal id="newsletter-edit-modal" class="modal-long">
      <form id="newsletter-edit-form" class="form form-modal">
        <h2>{state.isNew ? "Add" : "Edit"} Newsletter</h2>

        <Show when={!state.isNew}>
          <nav id="newsletter-edit-tabs" class="tabs">
            <a href="#" class="selected" onClick={switchTab.bind(null, "main")}>Main</a>
            <a href="#" onClick={switchTab.bind(null, "schedule")}>Schedule</a>
            <a href="#" onClick={switchTab.bind(null, "email")}>Email</a>
          </nav>
        </Show>

        <div id="newsletter-edit-main" class="newsletter-edit-tab">
          <p>
            <label>Name</label>
            <input type="text" name="name" maxLength="255" value={state.newsletter.name} placeholder="Newsletter Name" autofocus onInput={autocode} />
          </p>

          <p class="form-half-left">
            <label>Code</label>
            <input type="text" name="code" maxLength="255" placeholder="newsletter-name" value={state.newsletter.code} onInput={autocodeConfigure} class="input-with-help" />
            <span class="help">
              <span class="help-icon">?</span>
              <span class="help-text">Unique identifier for the newsletter. Also known as URL slug. Only alphanumeric characters, hyphens, or dashes allowed.</span>
            </span>
          </p>

          <p class="form-half-right">
            <label>URL</label>
            <input type="text" name="url" maxLength="2048" placeholder="https://example.com" value={state.newsletter.url || ""} class="input-with-help" />
            <span class="help">
              <span class="help-icon">?</span>
              <span class="help-text">URL of website where the embedded subscribe modal will be installed</span>
            </span>
          </p>

          <p class={`${state.isNew ? "" : "form-half-left"} clear`}>
            <label>Ask for Name?</label>
            <select name="name_type" id="newsletter-name-type-select">
              <option value="none">Don't Ask For Name</option>
              <option value="first_required">First Name, Required</option>
              <option value="first_optional">First Name, Optional</option>
              <option value="full_required">Full Name, Required</option>
              <option value="full_optional">Full Name, Optional</option>
            </select>
          </p>

          <Show when={!state.isNew}>
            <p class="form-half-right">
              <label>Track Emails?</label>
              <select name="track" id="newsletter-track-select">
                <option value="none">No Tracking</option>
                <option value="open">Track Opens</option>
                <option value="click">Track Clicks</option>
                <option value="all">Track Opens/Clicks</option>
              </select>
              <span class="help help-label">
                <span class="help-icon">?</span>
                <span class="help-text">If tracking opens/clicks, be sure to disclose to your subscribers to comply with regulations.</span>
              </span>
            </p>
          </Show>

          <Show when={state.isNew}>
            <p class="clear">
              <label>From Email</label>
              <input name="email__from_email" type="text" maxLength="255" placeholder="johndoe@example.com" value={state.newsletter.email.from || ""} class="input-with-help" />
              <span class="help">
                <span class="help-icon">?</span>
                <span class="help-text">Emails will come from this email address. Must be an Amazon SES verified sender.</span>
              </span>
            </p>

            <p>
              <label>Description <span class="suffix">(optional)</span></label>
              <textarea name="description" placeholder="Newsletter description for subscriber, as Markdown or HTML">{state.newsletter.description}</textarea>
            </p>
          </Show>
          <Show when={!state.isNew}>
            <p class="clear">
              <label>Description <span class="suffix">(optional)</span></label>
              <textarea name="description" placeholder="Newsletter description for subscriber, as Markdown or HTML">{state.newsletter.description}</textarea>
            </p>
          </Show>
        </div>

        <div id="newsletter-edit-schedule" class="newsletter-edit-tab hidden">
          <p>
            <label>Days of Week</label>
            <SelectMenu id="newsletter-days">
              <SelectOption value={0}>Sun</SelectOption>
              <SelectOption value={1}>Mon</SelectOption>
              <SelectOption value={2}>Tue</SelectOption>
              <SelectOption value={3}>Wed</SelectOption>
              <SelectOption value={4}>Thu</SelectOption>
              <SelectOption value={5}>Fri</SelectOption>
              <SelectOption value={6}>Sat</SelectOption>
            </SelectMenu>
            <span class="help help-label">
              <span class="help-icon">?</span>
              <span class="help-text">Which days of the week should drip emails be sent?</span>
            </span>
          </p>

          <p class="form-half-left">
            <label>Start Time</label>
            <select name="start_time">
              <For each={times()}>
                { pair => <option value={pair[0]}>{pair[1]}</option> }
              </For>
            </select>
            <span class="help help-label">
              <span class="help-icon">?</span>
              <span class="help-text">Don't send emails to subscribers before this time, in their time zone.</span>
            </span>
          </p>

          <p class="form-half-right">
            <label>End Time</label>
            <select name="end_time">
              <For each={times()}>
                { pair => <option value={pair[0]}>{pair[1]}</option> }
              </For>
            </select>
            <span class="help help-label">
              <span class="help-icon">?</span>
              <span class="help-text">Don't send emails to subscribers after this time, in their time zone.</span>
            </span>
          </p>
        </div>

        <Show when={!state.isNew}>
          <div id="newsletter-edit-email" class="newsletter-edit-tab hidden">
            <p>
              <label>Section</label>
              <select onChange={switchSection.bind(null, "email-section")}>
                <option value="addresses" selected>Email Addresses</option>
                <option value="template">Template</option>
                <option value="colors">Colors</option>
                <option value="subscribe-email">Subscribe Email Content</option>
              </select>
            </p>

            <div class="email-section" id="email-section-addresses">
              <p>
                <label>From Email</label>
                <input name="email__from_email" type="text" maxLength="255" placeholder="johndoe@example.com" value={state.newsletter.email.from_email || ""} class="input-with-help" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Emails will come from this address. Must be an Amazon SES verified sender.</span>
                </span>
              </p>

              <p>
                <label>From Name<span class="suffix">(optional)</span></label>
                <input name="email__from_name" type="text" maxLength="255" placeholder="John Doe" value={state.newsletter.email.from_name || ""} class="input-with-help" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Optional sender display name that's paired with the from email address.</span>
                </span>
              </p>

              <p>
                <label>Reply To Email<span class="suffix">(optional)</span></label>
                <input name="email__reply_to_email" type="text" maxLength="255" placeholder="johndoe@example.com" value={state.newsletter.email.reply_to_email || ""} class="input-with-help" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Email address used for the Reply-To section.</span>
                </span>
              </p>

              <p>
                <label>Reply To Name<span class="suffix">(optional)</span></label>
                <input name="email__reply_to_name" type="text" maxLength="255" placeholder="John Doe" value={state.newsletter.email.reply_to_name || ""} class="input-with-help" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Optional reply display name that's paired with the reply to email address.</span>
                </span>
              </p>
            </div>

            <div class="email-section hidden" id="email-section-template">
              <p>
                <label>Greeting <span class="suffix">(optional)</span></label>
                <textarea name="email__greeting" placeholder="{% if name %} Hi {{name}}, {% else %} Hi, {% endif %}">{state.newsletter.email.greeting}</textarea>
                <span class="help help-label">
                  <span class="help-icon">?</span>
                  <span class="help-text">Text to include at the beginning of all opt-in, drip, and broadcast emails. Written in Markdown or HTML. Leave blank to not include anything. Available placeholders are: {"{{"}name{"}}"}, {"{{"}first_name{"}}"}, and {"{{"}full_name{"}}"}.</span>
                </span>
              </p>

              <p>
                <label>Signature <span class="suffix">(optional)</span></label>
                <textarea name="email__signature" placeholder="Thanks for reading!">{state.newsletter.email.signature}</textarea>
                <span class="help help-label">
                  <span class="help-icon">?</span>
                  <span class="help-text">Text to include at the end of all opt-in, drip, and broadcast emails. Written in Markdown or HTML. Leave blank to not include anything.</span>
                </span>
              </p>

              <p>
                <label>Footer <span class="suffix">(optional)</span></label>
                <textarea name="email__footer" placeholder="Sent from {{newsletter_link | safe}} {% if unsubscribe_link %} {{separator | safe}} {{unsubscribe_link | safe}} {% endif %}">{state.newsletter.email.footer}</textarea>
                <span class="help help-label">
                  <span class="help-icon">?</span>
                  <span class="help-text">Text to include in the email's footer, as Markdown or HTML. Links to newsletter website and unsubscribe by default. All marketing emails must include a link to unsubscribe. Available placeholders are: {"{{"}newsletter_link{"}}"} and {"{{"}separator{"}}"}. Confirmed subscribers will also have: {"{{"}unsubscribe_link{"}}"} and {"{{"}unsubscribe_url{"}}"}.</span>
                </span>
              </p>

              <p>
                <label>Company Address</label>
                <input name="email__address" type="text" maxLength="1024" placeholder="123 Main St, San Francisco, CA 94115" value={state.newsletter.email.address} class="input-with-help" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Address to include in email's footer. Transactional emails (for initial opt-in) don't require an address. Newsletter/marketing emails require a physical address due to regulations.</span>
                </span>
              </p>
            </div>

            <div class="email-section hidden" id="email-section-colors">
              <p>
                <label>Theme Color <span class="suffix">(optional)</span></label>
                <input type="text" name="email__primary_color" value={state.newsletter.email.primary_color} class="form-input-hex" onInput={onInputHex} placeholder="#3f8efa" class="input-with-help" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Hex color used for email's call to action (eg button color). Defaults to blue.</span>
                </span>
              </p>

              <p>
                <label>Link Color <span class="suffix">(optional)</span></label>
                <input type="text" name="email__link_color" value={state.newsletter.email.link_color} class="form-input-hex" onInput={onInputHex} placeholder="#3f8efa" />
              </p>

              <p>
                <label>Link Hover Color <span class="suffix">(optional)</span></label>
                <input type="text" name="email__link_hover_color" value={state.newsletter.email.link_hover_color} class="form-input-hex" onInput={onInputHex} placeholder="#4f9ffb" />
              </p>
            </div>

            <div class="email-section hidden" id="email-section-subscribe-email">
              <p>
                Subscriptions are double opt-in. After a subscriber submits the sign up form, this confirmation email is sent to them to confirm their email address. These fields can be left blank to use the default text.
              </p>

              <p>
                <label>Subject <span class="suffix">(optional)</span></label>
                <input name="email__subscribe_subject" type="text" maxLength="2048" placeholder="Confirm subscription to {{newsletter_name}}" value={state.newsletter.email.subscribe_subject} class="input-with-help" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Subject for email. Available placeholder: {"{{"}newsletter_name{"}}"}.</span>
                </span>
              </p>

              <p>
                <label>Body <span class="suffix">(optional)</span></label>
                <textarea name="email__subscribe_body" placeholder="You can confirm your subscription to {{newsletter_name}} via the button below.">{state.newsletter.email.subscribe_body}</textarea>
                <span class="help help-label">
                  <span class="help-icon">?</span>
                  <span class="help-text">Content for email body. Available placeholder: {"{{"}newsletter_name{"}}"}.</span>
                </span>
              </p>

              <p>
                <label>Button Text <span class="suffix">(optional)</span></label>
                <input name="email__subscribe_button" type="text" maxLength="255" placeholder="Confirm Subscription" value={state.newsletter.email.subscribe_button} class="input-with-text" />
                <span class="help">
                  <span class="help-icon">?</span>
                  <span class="help-text">Label for main button. It directs customers back to your website, with a confirmation message.</span>
                </span>
              </p>
            </div>
          </div>
        </Show>

        <p>
          <input type="submit" onClick={submit} class="btn form-btn-full" value={state.isNew ? "Create" : "Save"} />
        </p>
      </form>
    </Modal>
  );
}
