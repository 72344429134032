import Request from "../utils/request";

export async function get(code: string, stats: boolean = false) {
  const includes = stats ? "?includes=stats" : "";
  const response = await Request.get(`/api/admin/newsletters/${code}${includes}`);
  return response.newsletter;
}

export async function list(stats: boolean = false, subscribersCount: boolean = false) {
  const includes = "";
  if (stats && subscribersCount) {
    includes = "?includes=stats&subscribers_count";
  } else if (stats) {
    includes = "?includes=stats";
  } else if (subscribersCount) {
    includes = "?includes=subscribers_count";
  }
  const response = await Request.get(`/api/admin/newsletters${includes}`);
  return response.newsletters;
}

export async function update(code: string, json: object) {
  const response = await Request.patch(`/api/admin/newsletters/${code}`, json);
  return response.newsletter;
}

export async function create(json: object) {
  const response = await Request.post(`/api/admin/newsletters`, json);
  return response.newsletter;
}

export async function destroy(code: string) {
  const response = await Request.destroy(`/api/admin/newsletters/${code}`);
  return response.newsletter;
}

export default {
  get,
  list,
  update,
  create,
  destroy
};
