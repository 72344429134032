import { createState } from "solid-js";
import { Show } from "solid-js/web";
import { Router, Route, Link, navigate } from "../components/router";
import { Menu, menuClick } from "../components/menu";
import { Modal, modalClick, closeModal } from "../components/modal";
import NewsletterEditModal from "../components/newsletter-edit-modal";
import NewsletterSubscribers from "../components/newsletter-subscribers";
import NewsletterDrips from "../components/newsletter-drips";
import NewsletterBroadcasts from "../components/newsletter-broadcasts";
import NewsletterInstall from "../components/newsletter-install";
import NewsletterManager from "../models/newsletter-manager";
import ChevronDown from "../icons/chevron-down";
import Stats from "../components/stats";
import { capitalize, num } from "../utils/format";

function NewsletterStats(props) {
  return (
    <Stats
      options={[
        {key: "subscribes_total", icon: "user-group", label: "Total Subscribes", value: num(props.newsletter.stats["subscribes_total"])},
        {key: "subscribes_30d", icon: "user-parents", label: "30d Subscribes", value: num(props.newsletter.stats["subscribes_30d"])},
        {key: "subscribes_7d", icon: "user", label: "7d Subscribes", value: num(props.newsletter.stats["subscribes_7d"])},

        {key: "unsubscribes_total", icon: "disappearing", label: "Total Unsubscribes", value: num(props.newsletter.stats["unsubscribes_total"])},
        {key: "unsubscribes_30d", icon: "hand-dislike", label: "30d Unsubscribes", value: num(props.newsletter.stats["unsubscribes_30d"])},
        {key: "unsubscribes_7d", icon: "user-no", label: "7d Unsubscribes", value: num(props.newsletter.stats["unsubscribes_7d"])},

        {key: "emails_total", icon: "inbox", label: "Total Emails", value: num(props.newsletter.stats["emails_total"])},
        {key: "emails_30d", icon: "envelope-full", label: "30d Emails", value: num(props.newsletter.stats["emails_30d"])},
        {key: "emails_7d", icon: "envelope", label: "7d Emails", value: num(props.newsletter.stats["emails_7d"])},

        {key: "drips_total", icon: "chat-conversation", label: "Total Drips", value: num(props.newsletter.stats["drips_total"])},
        {key: "drips_30d", icon: "chat-message", label: "30d Drips", value: num(props.newsletter.stats["drips_30d"])},
        {key: "drips_7d", icon: "drop", label: "7d Drips", value: num(props.newsletter.stats["drips_7d"])},

        {key: "broadcasts_total", icon: "satellite-dish", label: "Total Broadcasts", value: num(props.newsletter.stats["broadcasts_total"])},
        {key: "broadcasts_30d", icon: "announcement", label: "30d Broadcasts", value: num(props.newsletter.stats["broadcasts_30d"])},
        {key: "broadcasts_7d", icon: "bell", label: "7d Broadcasts", value: num(props.newsletter.stats["broadcasts_7d"])}
      ]}
      defaults={["suscribes_total", "30d_subscribes", "7d_subscribes", "emails_total"]}
      lsKey="stats:newsletter"
      wide
    />
  );
}

function deleteNewsletter(code) {
  const response = NewsletterManager.destroy(code);
  response.then(function() {
    navigate("/admin/newsletters");
  });
}

function DeleteModal(props) {
  return (
    <Modal id="newsletter-delete-modal">
      <h2>Delete Newsletter</h2>
      <p>
        Are you sure you want to delete <b>{props.newsletter.name}</b>? 
        Deleting a newsletter will remove all subscribers.
      </p>
      <p>
        <a href="#" class="btn btn-red" onClick={() => { deleteNewsletter(props.newsletter.code) }}>Confirm Deletion</a>
        <a href="#" class="btn btn-gray modal-close" onClick={closeModal} style="margin-left:1rem">Cancel</a>
      </p>
    </Modal>
  );
}

export default function(props) {
  const code = props.code;
  const [state, setState] = createState({ newsletter: null });

  function refresh() {
    const newsletterResponse = NewsletterManager.get(code, true);
    newsletterResponse.then(function(newsletter) {
      setState("newsletter", newsletter);
    });
  }
  refresh();

  return (
    <Show when={state.newsletter}>
      <h1>{state.newsletter.name}</h1>
      <div class="menu menu-up">
        <a class="btn btn-dropdown btn-small" onClick={menuClick("#newsletter-menu")}>Actions <ChevronDown /></a>
        <Menu id="newsletter-menu" style="width:10rem;top:1.65rem">
          <li><a href="#" onClick={modalClick("#newsletter-edit-modal")}>Edit Newsletter</a></li>
          <li><a href="#" onClick={modalClick("#newsletter-delete-modal")}>Delete Newsletter</a></li>
        </Menu>
      </div>

      <NewsletterStats newsletter={state.newsletter} />

      <Router>
        <nav class="tabs">
          <Link selectable href={`/admin/newsletters/${state.newsletter.code}`}>Subscribers</Link>
          <Link selectable href={`/admin/newsletters/${state.newsletter.code}/drips`}>Drips</Link>
          <Link selectable href={`/admin/newsletters/${state.newsletter.code}/broadcasts`}>Broadcasts</Link>
          <Link selectable href={`/admin/newsletters/${state.newsletter.code}/install`}>Install</Link>
        </nav>

        <Route key="newsletter-subscribers" path={`/admin/newsletters/${state.newsletter.code}`} component={NewsletterSubscribers} props={{ newsletter: state.newsletter, onUpdate: refresh }} />
        <Route key="newsletter-drips" path={`/admin/newsletters/${state.newsletter.code}/drips`} component={NewsletterDrips} props={{ newsletter: state.newsletter }} />
        <Route key="newsletter-broadcasts" path={`/admin/newsletters/${state.newsletter.code}/broadcasts`} component={NewsletterBroadcasts} props={{ newsletter: state.newsletter }} />
        <Route key="newsletter-install" path={`/admin/newsletters/${state.newsletter.code}/install`} component={NewsletterInstall} props={{ newsletter: state.newsletter }} />
      </Router>

      <DeleteModal newsletter={state.newsletter} />
      <NewsletterEditModal newsletter={state.newsletter} onSave={refresh} />
    </Show>
  );
}
