import { createState, createEffect } from "solid-js";
import { Show, For } from "solid-js/web";
import Stats from "../components/stats";
import { Link } from "../components/router";
import icon from "../utils/icon";
import DashboardManager from "../models/dashboard-manager";
import EnvManager from "../models/env-manager";
import LoadSpin from "../icons/load-spin";
import { dollars, num, pct } from "../utils/format";
import { createChart, Chart } from "../utils/chart";

function Loading() {
  return (
    <div class="loading">
      <LoadSpin />
    </div>
  );
}

function isEmptyStats(stats) {
  if (stats === null || stats === undefined) { return true; }
  if ((stats["revenue_total"] === undefined || stats["revenue_total"] === 0) &&
      (stats["sales_total"] === undefined || stats["sales_total"] === 0) &&
      (stats["subscribes_total"] === undefined || stats["subscribes_total"] === 0) &&
      (stats["unsubscribes_total"] === undefined || stats["unsubscribes_total"] === 0)) { return true; }
  return false;
}

function EmptyDashboard() {
  const envResponse = EnvManager.get();
  const [state, setState] = createState({ env: undefined });
  envResponse.then(function(env) {
    setState({ env });
  });

  return (
    <div class="empty">
      <img src="/assets/img/dashboard.svg" />
      <Show when={state.env === undefined}>
        <h1>Hello!</h1>
      </Show>
      <Show when={state.env}>
        <h1>Hi, {state.env.registered_name}!</h1>
      </Show>
      <p>
        This is your dashboard.<br/>
        You'll see activity about your products and newsletters here.
      </p>
      <p>
        <Link href="/admin/products" class="btn" style="margin-right:0.6rem">View Products</Link>
        <Link href="/admin/newsletters" class="btn btn-gray">View Newsletters</Link>
      </p>
    </div>
  );
}

function DashboardStats(props) {
  return (
    <Stats
      options={[
        {key: "revenue_total", icon: "bank", label: "Total Revenue", value: dollars(props.stats["revenue_total"])},
        {key: "revenue_30d", icon: "wallet", label: "30d Revenue", value: dollars(props.stats["revenue_30d"])},
        {key: "revenue_7d", icon: "coins", label: "7d Revenue", value: dollars(props.stats["revenue_7d"])},

        {key: "sales_total", icon: "cash-register-alt", label: "Total Sales", value: num(props.stats["sales_total"])},
        {key: "sales_30d", icon: "invoice", label: "30d Sales", value: num(props.stats["sales_30d"])},
        {key: "sales_7d", icon: "receipt", label: "7d Sales", value: num(props.stats["sales_7d"])},

        {key: "subscribes_total", icon: "user-group", label: "Total Subscribes", value: num(props.stats["subscribes_total"])},
        {key: "subscribes_30d", icon: "user-parents", label: "30d Subscribes", value: num(props.stats["subscribes_30d"])},
        {key: "subscribes_7d", icon: "user", label: "7d Subscribes", value: num(props.stats["subscribes_7d"])},

        {key: "unsubscribes_total", icon: "disappearing", label: "Total Unsubscribes", value: num(props.stats["unsubscribes_total"])},
        {key: "unsubscribes_30d", icon: "hand-dislike", label: "30d Unsubscribes", value: num(props.stats["unsubscribes_30d"])},
        {key: "unsubscribes_7d", icon: "user-no", label: "7d Unsubscribes", value: num(props.stats["unsubscribes_7d"])},

        {key: "emails_1d_pct", icon: "send", label: "Email Quota", value: pct(props.stats["emails_1d_pct"])},
      ]}
      defaults={["revenue_total", "sales_total", "subscribes_total", "emails_1d_pct"]}
      lsKey="stats:dashboard"
      wide
    />
  );
}

export default function() {
  const dashboardResponse = DashboardManager.get();
  const [state, setState] = createState({ stats: null, period: "total" });
  dashboardResponse.then(function(response) {
    setState("stats", response.stats);
  });

  let charts = [];
  createEffect(() => {
    if (state.stats !== null) {
      setTimeout(() => {
        if (charts.length === 0) {
          charts = setCharts(charts);
        }
      }, 1);
    }
  });

  function setPeriod(e) {
    setState("period", e.target.value);
    charts = setCharts(charts);
  }

  function setCharts(charts) {
    charts.forEach((chart) => {
      chart.clear();
      chart.destroy();
    });

    const dashboard = state.stats.dashboard;
    return [
      createChart("revenue", dashboard[`revenue_${state.period}`].labels, dashboard[`revenue_${state.period}`].values, "dollars"),
      createChart("sales", dashboard[`sales_${state.period}`].labels, dashboard[`sales_${state.period}`].values),
      createChart("subscribes", dashboard[`subscribes_${state.period}`].labels, dashboard[`subscribes_${state.period}`].values),
      createChart("unsubscribes", dashboard[`unsubscribes_${state.period}`].labels, dashboard[`unsubscribes_${state.period}`].values),
      createChart("broadcasts", dashboard[`broadcasts_${state.period}`].labels, dashboard[`broadcasts_${state.period}`].values),
      createChart("drips", dashboard[`drips_${state.period}`].labels, dashboard[`drips_${state.period}`].values)
    ];
  }

  return (<>
    <Show when={state.stats === null}>
      <Loading />
    </Show>
    <Show when={state.stats !== null && isEmptyStats(state.stats)}>
      <EmptyDashboard />
    </Show>
    <Show when={!isEmptyStats(state.stats)}>
      <h1>Dashboard</h1>
      <div class="menu menu-up-high form">
        <select style="width:6.5rem" onChange={setPeriod}>
          <option value="total" selected>All Time</option>
          <option value="30d">30 Days</option>
          <option value="7d">7 Days</option>
        </select>
      </div>
      <DashboardStats stats={state.stats} />
      <div class="charts">
        <Chart key="revenue" title="Revenue" value={state.stats.dashboard[`revenue_${state.period}`].total} format="dollars" />
        <Chart key="sales" title="Sales" value={state.stats.dashboard[`sales_${state.period}`].total} />
        <Chart key="subscribes" title="Subscribes" value={state.stats.dashboard[`subscribes_${state.period}`].total} />
        <Chart key="unsubscribes" title="Unsubscribes" value={state.stats.dashboard[`unsubscribes_${state.period}`].total} />
        <Chart key="broadcasts" title="Broadcasts" value={state.stats.dashboard[`broadcasts_${state.period}`].total} />
        <Chart key="drips" title="Drips" value={state.stats.dashboard[`drips_${state.period}`].total} />
        <div class="clear"></div>
      </div>
    </Show>
  </>);
}
