import { createEffect, createState } from "solid-js";
import { Show } from "solid-js/web";
import { Router, Route, Link } from "../components/router";
import AccountManager from "../models/account-manager";
import EnvManager from "../models/env-manager";
import LoadSpin from "../icons/load-spin";
import { queryJson } from "../utils/form";
import { notify, notifyError } from "../utils/notify";

function submitAuth(e) {
  e.preventDefault();
  const json = queryJson("#account-auth-form", ["email", "password"]);

  const accountResponse = AccountManager.updateAuth(json.email, json.password);
  accountResponse.then(function(account) {
    notify("Account has been updated", { autoclose: true });
  });
}

function Loading() {
  return (
    <div class="loading">
      <LoadSpin />
    </div>
  );
}

function AccountSettings(props) {
  return (
    <form class="form form-settings" id="account-auth-form">
      <h3>Account</h3>

      <p>
        <label>Email</label>
        <input type="text" name="email" value={props.account.email} placeholder="johndoe@example.com" />
      </p>

      <p>
        <label>Password</label>
        <input type="password" name="password" />
      </p>

      <p>
        <input type="submit" onClick={submitAuth} class="btn" value="Save Account" />
      </p>
    </form>
  );
}

function InstallSettings(props) {
  function select(e) {
    e.preventDefault();
    e.target.select();
  }

  return (
    <form class="form form-settings" id="account-install-form" onSubmit={(e) => e.preventDefault()}>
      <h3>Install <span class="suffix">(Important Webhooks and URLs)</span></h3>

      <p>
        <label>Stripe Purchase Webhook</label>
        <input type="text" name="text" value={`${props.env.url}/api/webhook/purchases`} readonly class="form-copy" onClick={select} />
      </p>

      <p>
        <label>Amazon SNS Subscription Webhook</label>
        <input type="text" name="text" value={`${props.env.url}/api/webhook/emails`} readonly class="form-copy" onClick={select} />
      </p>

      <p>
        <label>boutique.js URL</label>
        <input type="text" name="text" value={`${props.env.url}/boutique.js`} readonly class="form-copy" onClick={select} />
      </p>
    </form>
  );
}

export default function() {
  const envResponse = EnvManager.get();
  const accountResponse = AccountManager.get();
  const [state, setState] = createState({ env: undefined, account: undefined });
  Promise.all([envResponse, accountResponse]).then(function([env, account]) {
    setState({ env, account });
  });

  return (<>
    <Show when={state.env === undefined || state.account === undefined}>
      <Loading />
    </Show>

    <Show when={state.env && state.account}>
      <h1>Settings</h1>

      <div class="menu">
        Boutique v{ state.env.version } <span class="suffix">(registered to { state.env.registered_name } &lt;{ state.env.registered_email }&gt;)</span>
      </div>

      <Router>
        <nav class="tabs">
          <Link selectable href="/admin/settings">Account</Link>
          <Link selectable href="/admin/settings/install">Install</Link>
        </nav>

        <Route key="settings-account" path="/admin/settings" component={AccountSettings} props={{ env: state.env, account: state.account }} />
        <Route key="settings-install" path="/admin/settings/install" component={InstallSettings} props={{ env: state.env, account: state.account }} />
      </Router>
    </Show>
  </>);
}
