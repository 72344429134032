import { createEffect, createSignal, createState } from "solid-js";
import { For, Show } from "solid-js/web";
import { Link, getQueryValue } from "../components/router";
import { Modal, modalClick, openModal, closeModal } from "../components/modal";
import { SelectMenu, SelectOption, getSelected, setSelected } from "../components/select-menu";
import OptionManager from "../models/option-manager";
import Picture from "../icons/picture";
import Invoice from "../icons/invoice";
import StatsBar from "../icons/stats-bar";
import Star from "../icons/star";
import Menu from "../icons/menu";
import LoadSpin from "../icons/load-spin";
import icon from "../utils/icon";
import { num, money, status, nameCode, linkText } from "../utils/format";
import { bindForm, queryForm, queryInput, queryJson, onInputMoney } from "../utils/form";
import Sortable from "sortablejs";

let refreshOptions;
let setOptionDeleteModal;

const emptyOption = {
  uuid: null,
  code: "",
  name: "",
  description: "",
  descriptor: "",
  price: null,
  status: "draft",
  asset_uuids: []
};

function Loading() {
  return (
    <div class="loading loading-tab">
      <LoadSpin />
    </div>
  );
}

function HelpOptions(props) {
  return (
    <div class="empty">
      <div class="empty-left">
        <h2>What Are Options?</h2>
        <p>
          A product can be sold at different tiers. For example: Base, Plus, and Premium. 
          Boutique calls these tiers "Options". Each option can have a unique price and 
          different files.
        </p>
        <p>
          <Link class="btn btn-small" href={`/admin/products/${props.product.code}/assets`}>Add Files</Link>
        </p>
      </div>
      <div class="empty-right">
        <img src="/assets/img/option.svg" />
      </div>
    </div>
  );
}

function assetIcons(option) {
  function onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
  }

  if (!option.assets || option.assets.length === 0) {
    return "None";
  } else {
    const icons = option.assets.map(a => a.icon).filter(onlyUnique);
    return icons.slice(0, 5).map(i => icon(i));
  }
}

function submitOption(product, optionUuid, e) {
  e.preventDefault();
  const json = queryJson("#option-form", ["name", "code", "descriptor", "description", "price", "status"], {
    asset_uuids: getSelected("upload-assets") || [],
    product_code: product.code
  });

  if (optionUuid) {
    OptionManager.update(optionUuid, json).then(() => {
      refreshOptions();
      closeModal();
    });
  } else {
    OptionManager.create(json).then(() => {
      refreshOptions();
      queryForm("#option-form").reset();
      closeModal();
    });
  }
}

function confirmDeleteOption(option, e) {
  e.preventDefault();
  setOptionDeleteModal(option);
  closeModal();
  openModal("#option-delete-modal");
}

function deleteOption(option, e) {
  e.preventDefault();
  OptionManager.destroy(option.uuid).then(() => {
    refreshOptions();
    closeModal();
  });
}

function DeleteModal(props) {
  const [state, setState] = createState({ option: emptyOption });
  setOptionDeleteModal = (option) => { setState({ option }) };

  return (
    <Modal id="option-delete-modal">
      <div class="form">
        <h2>Delete Option</h2>
        <p>
          Are you sure you want to delete <b>{ state.option.name }</b>? 
          Existing purchases for this option will also be removed. Customers will 
          no longer have access to it.
        </p>
        <p>
          <a href="#" class="btn btn-red form-btn-half" onClick={deleteOption.bind(null, state.option)}>Confirm Deletion</a>
          <a href="#" class="btn btn-gray modal-close" onClick={closeModal}>Cancel</a>
        </p>
      </div>
    </Modal>
  );
}

interface Asset {
  uuid: number,
  name: string
}

function EditModal(props) {
  const [isNew, setNew] = createSignal(true);
  const [state, setState] = createState({ option: emptyOption });

  props.page.editOption = (opt, e) => {
    e.preventDefault();
    setNew(!opt.uuid);
    setState({ option: opt });
    openModal("#option-edit-modal");
    queryInput("#option-form [name=name]").focus();
  }

  createEffect((uuid = null) => {
    return bindForm("#option-form", state.option, uuid, ["description", "status"], () => {
      setSelected("upload-assets", state.option.asset_uuids);
    });
  });

  function autocode(e) {
    var field = document.querySelector("#option-form [name=code]");
    if (isNew && !autocode.off) {
      field.value = nameCode(e.target.value);
    }
  }

  function autocodeConfigure(e) {
    var code = e.target.value;
    var name = document.querySelector("#option-form [name=name]").value;
    autocode.off = nameCode(name) !== code;
  }

  return (
    <Modal id="option-edit-modal">
      <form id="option-form" class="form form-modal">
        <h2>{isNew() ? "New" : "Edit"} Option</h2>
        <p>
          <label>Name</label>
          <input type="text" name="name" maxLength="255" placeholder="Option Name" value={state.option.name} autofocus onInput={autocode}/>
        </p>
        <p class="form-half-left">
          <label>Code</label>
          <input type="text" name="code" maxLength="255" placeholder="option-name" value={state.option.code} onInput={autocodeConfigure} class="input-with-help" />
          <span class="help">
            <span class="help-icon">?</span>
            <span class="help-text">Unique identifier for the option. Also known as URL slug. Only alphanumeric characters, hyphens, or dashes allowed.</span>
          </span>
        </p>
        <p class="form-half-right">
          <label>Price</label>
          <input type="text" name="price" maxLength="12" class="form-input-money" placeholder="19.00" onInput={onInputMoney} value={money(state.option.price)} />
        </p>
        <p class="clear">
          <label>Descriptor</label>
          <input type="text" name="descriptor" maxLength="22" placeholder="Credit card statement description" value={state.option.descriptor} class="input-with-help" />
          <span class="help">
            <span class="help-icon">?</span>
            <span class="help-text">Text shown on your customer's credit card statement. Limited to 22 characters.</span>
          </span>
        </p>
        <p>
          <label>Description <span class="suffix">(optional)</span></label>
          <textarea name="description" placeholder="Description for customer, as Markdown or HTML">{state.option.description}</textarea>
        </p>
        <p>
          <label>Status</label>
          <select name="status" id="option-status-select">
            <option value="draft">Draft</option>
            <option value="active">Active</option>
          </select>
        </p>
        <Show when={props.assets.length > 0}>
          <p>
            <label>Assets</label>
            <SelectMenu id="upload-assets">
              <For each={props.assets}>
                { asset => <SelectOption value={(asset as Asset).uuid}>{(asset as Asset).name}</SelectOption> }
              </For>
            </SelectMenu>
          </p>
        </Show>
        <Show when={isNew()}>
          <p>
            <input type="submit" class="btn form-btn-full" onClick={submitOption.bind(null, props.product, null)} value="Create Option" />
          </p>
        </Show>
        <Show when={!isNew()}>
          <p>
            <input type="submit" class={`btn form-btn-${props.options.length > 1 ? "half" : "full"}`} onClick={submitOption.bind(null, props.product, state.option.uuid)} value="Update Option" />
            <Show when={props.options.length > 1}>
              <input type="submit" class="btn btn-gray form-btn-half" value="Delete" onClick={confirmDeleteOption.bind(null, state.option)} />
            </Show>
          </p>
        </Show>
      </form>
    </Modal>
  );
}

export default function(props) {
  const [state, setState] = createState({ options: undefined, assets: [], sorting: false });
  const showHelp = !!getQueryValue("help");

  var page = {
    editOption: Function.prototype
  };

  refreshOptions = () => {
    OptionManager.list(props.product.code, true, true).then((response) => {
      setState({ options: response.options, assets: response.assets, sorting: false });
    });
  };
  refreshOptions();

  function toggleSort(e) {
    e.preventDefault();
    Sortable.create(
      document.getElementById("options-table-tbody"), {
        handle: ".table-sort-icon",
        dragClass: "table-sort-drag"
      }
    );
    if (!state.sorting) {
      setState({ sorting: true });
    } else { // save changes
      const rows = document.querySelectorAll("#options-table-tbody tr");
      const uuids: Array<string> = [];
      rows.forEach((row) => {
        uuids.push(row.getAttribute("data-uuid"));
      });
      OptionManager.updatePositions(props.product.code, uuids).then(() => {
        setState({ options: undefined, assets: [], sorting: false });
        refreshOptions();
      });
    }
  }

  function cancelSort(e) {
    e.preventDefault();
    setState({ options: undefined, assets: [], sorting: false });
    refreshOptions();
  }

  return (<>
    <Show when={state.options !== undefined}>
      <h3>Options</h3>
      <div class="menu">
        <Show when={!state.sorting}>
          <Show when={state.options.length > 1}>
            <a href="#" onClick={toggleSort}>Sort</a>
            <span class="slash">/</span>
          </Show>
          <a href="#" onClick={(e) => page.editOption(emptyOption, e)}>Add Option</a>
        </Show>
        <Show when={state.sorting}>
          <a href="#" onClick={toggleSort}>Save Changes</a>
          <span class="slash">/</span>
          <a href="#" onClick={cancelSort}>Cancel</a>
        </Show>
      </div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th class="table-col-assets">Assets</th>
            <th class="align-right">Price</th>
            <th class="align-right">Revenue</th>
            <th class="align-right"># Sales</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody id="options-table-tbody" class={state.sorting ? "table-sorting" : ""}>
          <For each={state.options}>
            {option =>
              <tr data-uuid={option.uuid}>
                <td>
                  <span class="table-sort-icon"><Menu /></span>
                  <a href="#" onClick={state.sorting ? (e) => { e.preventDefault() } : page.editOption.bind(null, option)}>
                    {linkText(option.name)}
                  </a>
                </td>
                <td class="table-col-assets">
                  <span class="table-assets">
                    {assetIcons(option)}
                  </span>
                </td>
                <td class="align-right">{money(option.price)}</td>
                <td class="align-right">{money(option.stats.revenue)}</td>
                <td class="align-right">{num(option.stats.sales)}</td>
                <td>{status(option.status)}</td>
              </tr>
            }
          </For>
        </tbody>
      </table>
      <Show when={showHelp}>
        <HelpOptions product={props.product} />
      </Show>
    </Show>
    <Show when={state.options === undefined}>
      <Loading />
    </Show>
    <EditModal page={page} product={props.product} assets={state.assets} options={state.options || []} />
    <DeleteModal />
  </>);
}
