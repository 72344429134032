import { createEffect, createState, onCleanup } from "solid-js";
import { For, Show } from "solid-js/web";
import { Link } from "../components/router";
import { Modal, openModal, closeModal } from "../components/modal";
import BroadcastManager from "../models/broadcast-manager";
import LoadSpin from "../icons/load-spin";
import ChevronLeft from "../icons/chevron-left";
import ChevronRight from "../icons/chevron-right";
import ChevronDown from "../icons/chevron-down";
import StatsBar from "../icons/stats-bar";
import { num, money, date, datetime, linkText } from "../utils/format";
import { bindForm, queryInput, queryForm, queryJson, onInputMoney } from "../utils/form";

interface Broadcast {
  uuid: string,
  subject: string,
  body: string,
  recipients: number,
  first_sent_at: string,
  last_sent_at: string,
  stats: SendableStats
}

interface SendableStats {
  emails: number,
  soft_bounced: number,
  hard_bounced: number,
  rejected: number,
  complaint: number,
  opened: number,
  clicked: number,
  unsubscribed: number
}

const emptyBroadcast = {
  uuid: null,
  subject: "",
  body: "",
  recipients: 0,
  first_sent_at: null,
  last_sent_at: null,
  stats: {
    emails: 0,
    soft_bounced: 0,
    hard_bounced: 0,
    rejected: 0,
    complaint: 0,
    opened: 0,
    clicked: 0,
    unsubscribed: 0
  }
};

function EmptyBroadcasts(props) {
  return (
    <div class="empty">
      <img src="/assets/img/broadcast.svg" />
      <h1>No Broadcasts</h1>
      <p>
        Broadcasts are emails sent to all subscribers immediately, one single time.<br />
        No broadcast emails have been created yet.
      </p>
      <p>
        <Link class="btn" href={`/admin/broadcasts/new?newsletter=${props.newsletter.code}`}>Add Broadcast</Link>
      </p>
    </div>
  );
}

function LoadingBroadcasts() {
  return (
    <div class="loading loading-tab">
      <LoadSpin />
    </div>
  );
}

export default function(props) {
  const [state, setState] = createState({ broadcasts: undefined });
  const page = {
    refreshBroadcasts: (e) => {
      if (e) { e.preventDefault(); }
      BroadcastManager.list(props.newsletter.code, true).then((broadcasts) => {
        setState({ broadcasts });
      });
    },
    submitBroadcast: (newsletter, broadcastUuid, e) => {
      e.preventDefault();
      const json = queryJson(
        "#broadcast-form",
        ["subject", "body"],
        { newsletter_code: props.newsletter.code }
      );

      if (broadcastUuid) {
        BroadcastManager.update(broadcastUuid, json).then(() => {
          page.refreshBroadcasts();
          closeModal();
        });
      } else {
        BroadcastManager.create(json).then(() => {
          page.refreshBroadcasts();
          queryForm("#broadcast-form").reset();
          closeModal();
        });
      }
    }
  };
  page.refreshBroadcasts();

  return (<>
    <Show when={state.broadcasts === undefined}>
      <LoadingBroadcasts />
    </Show>
    <Show when={state.broadcasts && state.broadcasts.length <= 0}>
      <EmptyBroadcasts newsletter={props.newsletter} page={page} />
    </Show>
    <Show when={state.broadcasts && state.broadcasts.length > 0}>
      <h3>Broadcasts <span class="suffix">({ state.broadcasts.length })</span></h3>
      <div class="menu">
        <Link href={`/admin/broadcasts/new?newsletter=${props.newsletter.code}`}>Add Broadcast</Link>
      </div>
      <table>
        <thead>
          <tr>
            <th>Subject</th>
            <th class="align-right">Recipients</th>
            <th>Sent At</th>
          </tr>
        </thead>
        <tbody>
          <For each={state.broadcasts}>
            {(broadcast: Broadcast) =>
              <tr>
                <td><Link href={`/admin/broadcasts/${broadcast.uuid}`}>{linkText(broadcast.subject)}</Link></td>
                <td class="align-right">
                  {num(broadcast.recipients)}
                  <span class="help help-details help-details-stats">
                    <span class="help-icon"><StatsBar /></span>
                    <span class="help-text">
                      <Show when={props.newsletter.track === "open" || props.newsletter.track === "all"}>
                        {num(broadcast.stats.opened)} Opened <br/>
                      </Show>
                      <Show when={props.newsletter.track === "click" || props.newsletter.track === "all"}>
                        {num(broadcast.stats.clicked)} Clicked <br/>
                      </Show>
                      {num(broadcast.stats.unsubscribed)} Unsubscribed <br/>
                      {num(broadcast.stats.soft_bounced)} Soft Bounced <br/>
                      {num(broadcast.stats.hard_bounced)} Hard Bounced <br/>
                      {num(broadcast.stats.rejected)} Rejected <br/>
                      {num(broadcast.stats.complaint)} Complained <br/>
                    </span>
                  </span>
                </td>
                <td>{broadcast.last_sent_at ? datetime(broadcast.last_sent_at) : "Never Sent"}</td>
              </tr>
            }
          </For>
        </tbody>
      </table>
    </Show>
  </>);
}
