import currentSession from "../models/current-session";
import Session from "../models/session";
import { navigate } from "../components/router";
import { notify } from "./notify";

export async function get(url) {
  const response = await makeRequest(url, {
    headers: headers()
  });
  return response.json();
};

export async function patch(url, data) {
  const response = await makeRequest(url, {
    method: "PATCH",
    headers: headers(),
    body: JSON.stringify(data)
  });
  return response.json();
};

export async function patchForm(url, formData) {
  const response = await makeRequest(url, {
    method: "PATCH",
    headers: headers({ skipContentType: true }),
    body: formData
  });
  return response.json();
};

export async function post(url, data) {
  const response = await makeRequest(url, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(data)
  });
  return response.json();
};

export async function postForm(url, formData) {
  const response = await makeRequest(url, {
    method: "POST",
    headers: headers({ skipContentType: true }),
    body: formData
  });
  return response.json();
};

export async function destroy(url) {
  const response = await makeRequest(url, {
    method: "DELETE",
    headers: headers()
  });
  return response.json();
};

export async function refresh() {
  try {
    const response = await patch("/api/auth", {});
    currentSession.set(response.access);
    return true;
  } catch(error) {
    currentSession.set(null);
  }
}

function headers(options = { skipContentType: false }) {
  const rows = {}
  if (!options.skipContentType) {
    rows["Content-Type"] = "application/json";
  }
  if (currentSession.loggedIn) {
    rows["Authorization"] = `Bearer ${currentSession.accessToken}`;
  }
  return rows;
}

async function makeRequest(url, options, refreshOnExpired = true) {
  const response = await fetch(url, options);
  if (!response.ok && response.status === 500) {
    notify("Sorry! A server error occurred. Please try reloading this page.", { icon: "square-alert" });
  } else if (!response.ok && response.headers.get("Content-Type") === "application/json") {
    const json = await response.json();
    if (json.error && json.error === "unauthenticated" && refreshOnExpired) { // refresh and try again
      await refresh();
      if (currentSession.loggedIn) {
        options["headers"]["Authorization"] = `Bearer ${currentSession.accessToken}`; // new token
        return await makeRequest(url, options, false);
      } else {
        navigate("/");
        notify("Session has expired, please log in and try again.", { icon: "square-alert" });
      }
    } else if (json.error && json.message) {
      notify(json.message, { icon: "square-alert" });
    }
  }
  return response;
}

export default {
  get,
  patch,
  patchForm,
  post,
  postForm,
  destroy,
  refresh
};
