import { createState, onCleanup } from "solid-js";
import { Router, Route, Link, navigate } from "../components/router";
import { Menu, menuClick } from "../components/menu";
import { createQuickModal } from "../components/quick-modal";
import Session from "../models/session";
import currentSession from "../models/current-session";
import { Show } from "solid-js/web";
import Dashboard from "./dashboard";
import Products from "./products";
import Product from "./product";
import Users from "./users";
import Settings from "./settings";
import Login from "./login";
import Newsletters from "./newsletters";
import Newsletter from "./newsletter";
import BroadcastEdit from "./broadcast-edit";
import DripEdit from "./drip-edit";
import ChevronDown from "../icons/chevron-down";
import ChevronRight from "../icons/chevron-right";

function logout(e) {
  e.preventDefault();
  Session.logout();
  navigate("/");
}

export default function() {
  const [state, setState] = createState({ loggedIn: undefined, quickMode: "compose" });
  const unlistenLogin = currentSession.listen((session) => {
    setState({ loggedIn: session.loggedIn });
    if (!session.loggedIn && location.pathname !== "/admin") {
      navigate("/admin");
    }
  });
  onCleanup(() => {
    unlistenLogin();
  });
  Session.refresh();

  const productPaths = [
    "/admin/products/:code",
    "/admin/products/:code/assets",
    "/admin/products/:code/options",
    "/admin/products/:code/install"
  ];
  const newsletterPaths = [
    "/admin/newsletters/:code",
    "/admin/newsletters/:code/drips",
    "/admin/newsletters/:code/broadcasts",
    "/admin/newsletters/:code/install"
  ];
  const settingsPaths = [
    "/admin/settings",
    "/admin/settings/install"
  ];
  const [openQuickModal, QuickModal] = createQuickModal();

  function quickCompose(e) {
    setState({ quickMode: "compose" });
    openQuickModal(e, "compose");
  }

  function quickUpload(e) {
    setState({ quickMode: "upload" });
    openQuickModal(e, "upload");
  }

  return (<>
    <Show when={typeof(state.loggedIn) !== "undefined" && !state.loggedIn}>
      <Login />
    </Show>
    <Show when={state.loggedIn}>
      <Router>
        <header>
          <nav>
            <span class="btn-split">
              <a class="btn" href="#" onClick={(e) => openQuickModal(e, state.quickMode)}>
                {state.quickMode === "compose" ? "Compose" : "Upload"}
              </a>
              <a class="btn btn-chevron" href="#" onClick={menuClick("#quick-menu")}><ChevronDown /></a>
              <Menu id="quick-menu">
                <li><a href="#" onClick={(e) => quickCompose(e)}>Compose</a></li>
                <li><a href="#" onClick={(e) => quickUpload(e)}>Upload</a></li>
              </Menu>
            </span>

            <ul>
              <li><Link selectable href="/admin">Dashboard</Link></li>
              <li><Link selectable="partial" href="/admin/products">Products</Link></li>
              <li><Link selectable="partial" href="/admin/newsletters">Newsletters</Link></li>
              {/*
              <li><Link selectable="partial" href="/admin/users">Users</Link></li>
              */}
              <li><Link selectable="partial" href="/admin/settings">Settings</Link></li>
              <li><a href="/signout" onClick={logout}>Sign Out</a></li>
            </ul>
          </nav>
        </header>

        <main>
          <Route key="dashboard" path="/admin" component={Dashboard} props={{}} />
          <Route key="products" path="/admin/products" component={Products} props={{}} />
          <Route key="product" path={productPaths} component={Product} props={{}} />
          <Route key="newsletters" path="/admin/newsletters" component={Newsletters} props={{}} />
          <Route key="newsletter" path={newsletterPaths} component={Newsletter} props={{}} />
          <Route key="broadcast-edit" path="/admin/broadcasts/:uuid" component={BroadcastEdit} props={{}} />
          <Route key="drip-edit" path="/admin/drips/:uuid" component={DripEdit} props={{}} />
          {/*
          <Route key="users" path="/admin/users" component={Users} props={{}} />
          */}
          <Route key="settings" path={settingsPaths} component={Settings} props={{}} />
        </main>
        <QuickModal />
      </Router>
    </Show>
    <div id="notify"></div>
  </>);
}
