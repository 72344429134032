import Request from "../utils/request";

export async function list(newsletterCode: string, page: number = 1) {
  const url = `/api/admin/subscribers?newsletter_code=${newsletterCode}&page=${page}`
  const response = await Request.get(url);
  return response;
}

export async function create(json: object) {
  const response = await Request.post(`/api/admin/subscribers`, json);
  return response.subscriber;
}

export async function update(uuid: string, json: object) {
  const response = await Request.patch(`/api/admin/subscribers/${uuid}`, json);
  return response.subscriber;
}

export async function destroy(uuid: string) {
  const response = await Request.destroy(`/api/admin/subscribers/${uuid}`);
  return response.subscriber;
}

export default {
  list,
  update,
  create,
  destroy
};
