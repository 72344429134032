import { createEffect, createState, onCleanup } from "solid-js";
import { For, Show } from "solid-js/web";
import { Link } from "../components/router";
import { Modal, openModal, closeModal } from "../components/modal";
import SubscriberManager from "../models/subscriber-manager";
import EnvManager from "../models/env-manager";
import ChevronLeft from "../icons/chevron-left";
import ChevronRight from "../icons/chevron-right";
import ChevronDown from "../icons/chevron-down";
import LoadSpin from "../icons/load-spin";
import { money, date, tzLabel, linkText } from "../utils/format";
import { isPresent } from "../utils/helper";
import { bindForm, queryInput, queryForm, queryJson, onInputMoney } from "../utils/form";

const emptySubscriber = {
  uuid: null,
  user: {
    email: "",
    full_name: "",
    first_name: "",
    timezone: "local"
  }
};

function Loading() {
  return (
    <div class="loading loading-tab">
      <LoadSpin />
    </div>
  );
}

function EmptySubscribers(props) {
  return (
    <div class="empty">
      <img src="/assets/img/subscriber.svg" />
      <h1>No Subscribers</h1>
      <p>
        Nobody has subscribed to this newsletter yet.
      </p>
      <p>
        <a class="btn" href="#" onClick={(e) => props.controller.editSubscriber(emptySubscriber, e)}>Add Subscriber</a>
      </p>
    </div>
  );
}

function EditModal(props) {
  const [state, setState] = createState({ isNew: true, subscriber: emptySubscriber, timezones: ["local"] });

  EnvManager.get().then((env) => {
    setState({ timezones: env.timezones });
  });

  props.controller.editSubscriber = (subscriber, e) => {
    e.preventDefault();
    setState({ isNew: !subscriber.uuid, subscriber });
    openModal("#subscriber-edit-modal");
    queryInput("#subscriber-form [name=email]").focus();
  };

  createEffect((uuid = null) => {
    return bindForm("#subscriber-form", state.subscriber, uuid, [{name: "timezone", value: state.subscriber.user.timezone}]);
  });

  return (
    <Modal id="subscriber-edit-modal">
      <form id="subscriber-form" class="form form-modal">
        <h2>{state.isNew ? "Add" : "Edit"} Subscriber</h2>
        <p>
          <label>Email Address</label>
          <input type="text" name="email" maxLength="255" placeholder="johndoe@example.com" value={state.subscriber.user.email} />
        </p>
        <Show when={props.newsletter.name_type.includes("first")}>
          <p>
            <label>First Name <Show when={props.newsletter.name_type === "first_optional"}><span class="suffix">(optional)</span></Show></label>
            <input type="text" name="first_name" maxLength="255" placeholder="John" value={state.subscriber.user.first_name} id="subscriber-edit-name" />
          </p>
        </Show>
        <Show when={props.newsletter.name_type.includes("full")}>
          <p>
            <label>Full Name <Show when={props.newsletter.name_type === "full_optional"}><span class="suffix">(optional)</span></Show></label>
            <input type="text" name="full_name" maxLength="255" placeholder="John Doe" value={state.subscriber.user.full_name} id="subscriber-edit-name" />
          </p>
        </Show>
        <Show when={!state.isNew}>
        <p>
          <label>Time Zone</label>
          <select name="timezone">
            <For each={state.timezones}>
              { timezone => <option value={timezone}>{tzLabel(timezone)}</option> }
            </For>
          </select>
        </p>
        </Show>
        <Show when={state.isNew}>
          <p>
            <input type="submit" class="btn form-btn-full" onClick={props.controller.submitSubscriber.bind(null, props.newsletter, null)} value="Create" />
          </p>
        </Show>
        <Show when={!state.isNew}>
          <p>
            <input type="submit" class="btn form-btn-half" onClick={props.controller.submitSubscriber.bind(null, props.newsletter, state.subscriber.uuid)} value="Update" />
            <input type="submit" class="btn btn-gray form-btn-half" value="Delete" onClick={props.controller.deleteSubscriber.bind(null, state.subscriber)} />
          </p>
        </Show>
      </form>
    </Modal>
  );
}

function DeleteModal(props) {
  const [state, setState] = createState({ subscriber: emptySubscriber });
  props.controller.deleteSubscriber = (subscriber, e) => {
    e.preventDefault();
    setState({ subscriber });
    closeModal();
    openModal("#subscriber-delete-modal");
  };

  function destroy(subscriber) {
    SubscriberManager.destroy(subscriber.uuid).then(() => {
      props.controller.refreshSubscribers();
      closeModal();
    });
  }

  return (
    <Modal id="subscriber-delete-modal">
      <div class="form">
        <h2>Delete Subscriber</h2>
        <p>
          Are you sure you want to remove <b>{ state.subscriber.user.email }</b>? 
          This subscriber will no longer have access to { props.newsletter.name }.
        </p>
        <p>
          <a href="#" class="btn btn-red form-btn-half" onClick={destroy.bind(null, state.subscriber)}>Confirm Deletion</a>
          <a href="#" class="btn btn-gray modal-close" onClick={closeModal}>Cancel</a>
        </p>
      </div>
    </Modal>
  );
}

export default function(props) {
  const [state, setState] = createState({
    subscribers: undefined,
    total: 0,
    page: 1,
    pagesTotal: 1
  });
  const controller = {
    refreshSubscribers: (page : number = 1, e = null, onUpdate = false) => {
      if (e) { e.preventDefault(); }
      SubscriberManager.list(props.newsletter.code, page).then((response) => {
        setState({
          subscribers: response.subscribers,
          total: response.total,
          page: response.page,
          pagesTotal: response.pages_total
        });
      });
      if (props.onUpdate && onUpdate) {
        props.onUpdate();
      }
    },
    editSubscriber: null,
    submitSubscriber: (newsletter, subscriberUuid, e) => {
      e.preventDefault();
      const isNew = !subscriberUuid;
      const fields = isNew ? ["email"] : ["timezone", "email"];
      const nullFields = {};
      const el = document.getElementById("subscriber-edit-name");
      const hasName = el && isPresent(el.value);
      console.log(hasName);

      // must allow blank names on updates, in case newsletter changes from optional to required
      if (newsletter.name_type === "first_required") {
        if (isNew || hasName) {
          fields.push("first_name");
        } else {
          nullFields["first_name"] = null;
        }
      } else if (newsletter.name_type === "first_optional") {
        if (hasName) {
          fields.push("first_name");
        } else {
          nullFields["first_name"] = null;
        }
      } else if (newsletter.name_type === "full_required") {
        if (isNew || hasName) {
          fields.push("full_name");
        } else {
          nullFields["full_name"] = null;
        }
      } else if (newsletter.name_type === "full_optional") {
        if (hasName) {
          fields.push("full_name");
        } else {
          nullFields["full_name"] = null;
        }
      }

      const json = queryJson(
        "#subscriber-form",
        fields,
        Object.assign({}, { newsletter_code: newsletter.code }, nullFields)
      );

      if (subscriberUuid) {
        SubscriberManager.update(subscriberUuid, json).then(() => {
          controller.refreshSubscribers(1, null, true);
          closeModal();
        });
      } else {
        SubscriberManager.create(json).then(() => {
          controller.refreshSubscribers(1, null, true);
          queryForm("#subscriber-form").reset();
          closeModal();
        });
      }
    }
  };
  controller.refreshSubscribers();

  return (<>
    <Show when={state.subscribers !== undefined && state.subscribers.length > 0}>
      <h3>Subscribers <span class="suffix">({ state.total })</span></h3>
      <div class="menu">
        <Show when={state.pagesTotal > 1}>
          <Show when={state.page > 1}>
            <a href="#" onClick={controller.refreshSubscribers.bind(null, state.page - 1)}><ChevronLeft /> Prev</a>
          </Show>
          <span class="menu-paginate">{state.page} of {state.pagesTotal}</span>
          <Show when={state.page < state.pagesTotal}>
            <a href="#" onClick={controller.refreshSubscribers.bind(null, state.page + 1)}>Next <ChevronRight /></a>
          </Show>
          <span class="slash">/</span>
        </Show>
        <a href="#" onClick={(e) => controller.editSubscriber(emptySubscriber, e)}>Add Subscriber</a>
      </div>
      <table>
        <thead>
          <tr>
            <th>Email</th>
            <Show when={props.newsletter.name_type.includes("first")}>
              <th>First Name</th>
            </Show>
            <Show when={props.newsletter.name_type.includes("full")}>
              <th>Full Name</th>
            </Show>
            <th>Subscribed On</th>
          </tr>
        </thead>
        <tbody>
          <For each={state.subscribers}>
            {subscriber =>
              <tr>
                <td><a href="#" onClick={controller.editSubscriber.bind(null, subscriber)}>{linkText(subscriber.user.email)}</a></td>
                <Show when={props.newsletter.name_type.includes("first")}>
                  <td>{subscriber.user.first_name}</td>
                </Show>
                <Show when={props.newsletter.name_type.includes("full")}>
                  <td>{subscriber.user.full_name}</td>
                </Show>
                <td>{date(subscriber.created_at)}</td>
              </tr>
            }
          </For>
        </tbody>
      </table>
    </Show>
    <Show when={state.subscribers !== undefined && state.subscribers.length <= 0}>
      <EmptySubscribers controller={controller} />
    </Show>
    <Show when={state.subscribers === undefined}>
      <Loading />
    </Show>
    <EditModal newsletter={props.newsletter} controller={controller} />
    <DeleteModal newsletter={props.newsletter} controller={controller} />
  </>);
}
