import { createContext, createState, onCleanup, useContext } from "solid-js";
import { Show } from "solid-js/web";
import { createBrowserHistory } from "history";
import RadixRouter from "../vendor/radix-router";

const history = createBrowserHistory();
const RouterContext = createContext({ history, radix: null }); /* TODO: fix, context should be in fn? */

function getQueryValues() {
  const query = window.location.search.substring(1);
  if (query === "") { return {}; }

  const vars = query.split("&");
  const values = {};
  for (let i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    values[pair[0]] = pair[1];
  }
  return values;
}

function push(history, href) {
  if (href.includes("?")) {
    history.push(href);
  } else {
    history.push({ pathname: href, search: "" });
  }
}

export function getQueryValue(key) {
  if (window.location.search === "") { return; }
  const vars = window.location.search.substring(1).split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === key) {
      return decodeURIComponent(pair[1]);
    }
  }
}

export function Router(props) {
  const radix = new RadixRouter();

  return (
    <RouterContext.Provider value={{ history, radix }}>
      {props.children}
    </RouterContext.Provider>
  );
}

export function Route(props) {
  const { history, radix } = useContext(RouterContext);
  const [state, setState] = createState({ key: null, params: {} });
  const unlistenLocation = history.listen(({ location }) => {
    const { data, params } = radix.lookup(location.pathname) || { data: {}, params: {} };
    // params = Object.assign(getQueryValues(), params, {});
    // TODO: replace usage of JSON stringify
    if (state.key !== data.key || JSON.stringify(params) !== JSON.stringify(state.params)) {
      setState({
        key: data.key,
        params
      });
    }
  });
  onCleanup(() => {
    unlistenLocation();
  });
  if (typeof(props.path) === "object") {
    for (let path of props.path) {
      radix.insert({
        path: path,
        data: { key: props.key }
      });
    }
  } else {
    radix.insert({
      path: props.path,
      data: { key: props.key }
    });
  }
  let { data, params } = radix.lookup(location.pathname) || { data: {}, params: {} };
  setState({
    key: data.key,
    params: params//Object.assign(getQueryValues(), params, {})
  });

  return (
    <Show when={state.key === props.key}>
      {props.component(Object.assign(state.params || {}, props.props, {}))}
    </Show>
  );
}

export function Link(props) {
  const { history } = useContext(RouterContext);
  const [state, setState] = createState({ selected: false });

  function handleClick(e) {
    e.preventDefault();
    push(history, props.href);
  }

  if (props.selectable) {
    const unlistenLocation = history.listen(({ location }) => {
      const selected = 
        props.selectable === "partial" ?
          location.pathname.startsWith(props.href) :
          (location.pathname === props.href || location.pathname === `${props.href}/`);
      setState({ selected });
    });
    onCleanup(() => {
      unlistenLocation();
    });
    const selected = 
      props.selectable === "partial" ?
        location.pathname.startsWith(props.href) :
        (location.pathname === props.href || location.pathname === `${props.href}/`);
    setState({ selected });
  }

  return (
    <a class={(props.class || "") + (state.selected ? " selected" : "")} {...props} onClick={handleClick}>{props.children}</a>
  );
}

interface NavigateOptions {
  replace?: boolean,
  force?: boolean // reload if already on page
}

export function navigate(path, options : NavigateOptions = {}) {
  if (options.replace) {
    history.replace(path);
  } else {
    const force = options.force && path.split("?")[0] === window.location.pathname;
    push(history, path);
    if (force) { history.go(); } // reload page, useful when using query string
  }
}
