import Request from "../utils/request";
import currentSession from "./current-session";
import EnvManager from "./env-manager";

const Session = {
  login: async function(email: string, password: string) {
    const response = await Request.post("/api/auth", { email, password });
    currentSession.set(response.access);
    await EnvManager.reset();
    return true;
  },

  refresh: async function() {
    Request.refresh();
  },

  logout: async function() {
    const response = await Request.destroy("/api/auth");
    currentSession.set(null);
    return true;
  }
};

export default Session;
