import { onMount, createEffect, onCleanup, createState } from "solid-js";
import { Show, For } from "solid-js/web";
import { Link, navigate, getQueryValue } from "../components/router";
import { Menu, menuClick } from "../components/menu";
import { Modal, modalClick, closeModal } from "../components/modal";
import { createRecipientsModal } from "../components/recipients-modal";
import { createNewsletterAssetsModal } from "../components/newsletter-assets-modal";
import LoadSpin from "../icons/load-spin";
import ChevronDown from "../icons/chevron-down";
import ChevronLeft from "../icons/chevron-left";
import DripManager from "../models/drip-manager";
import NewsletterManager from "../models/newsletter-manager";
import { bindForm, queryInput, queryJson, onInputNum } from "../utils/form";
import { date, num, pluralize, capitalize } from "../utils/format";
import { notify } from "../utils/notify";
import EasyMDE from "../vendor/easymde";

function Loading() {
  return (
    <div class="loading loading-full"><LoadSpin /></div>
  );
}

function DeleteModal(props) {
  function destroy(e) {
    e.preventDefault();
    DripManager.destroy(props.drip.uuid).then(() => {
      navigate(`/admin/newsletters/${props.drip.newsletter.code}/drips`);
    });
  }

  return (
    <Modal id="drip-delete-modal">
      <div class="form">
        <h2>Delete Drip</h2>
        <p>
          Are you sure you want to remove <b>{ props.drip.subject }</b>? 
        </p>
        <p>
          <a href="#" class="btn btn-red form-btn-half" onClick={destroy}>Confirm Deletion</a>
          <a href="#" class="btn btn-gray modal-close" onClick={closeModal}>Cancel</a>
        </p>
      </div>
    </Modal>
  );
}

function SettingsModal({ drip, page }) {
  function save(e) {
    e.preventDefault();
    const json = queryJson("#drip-settings-form", ["days", "status"]);
    if (json.days === null) { delete json.days; }
    if (drip.uuid) {
      DripManager.update(drip.uuid, json).then(() => {
        page.setDrip(json);
        closeModal();
      });
    } else {
      page.setDrip(json);
      closeModal();
    }
  }

  createEffect((uuid = null) => {
    return bindForm("#drip-settings-form", drip, uuid, ["status"]);
  });

  return (
    <Modal id="drip-settings-modal">
      <form id="drip-settings-form" class="form form-modal">
        <h2>Settings</h2>

        <p>
          Wait
          <input type="text" name="days" value={drip.days} placeholder="0" autofocus style="display:inline-block;text-align:center;width:2rem;margin:0 0.25rem;padding:0.2rem;" class="form-input-num" onInput={onInputNum} />
          days after last email before sending
          <span class="help" style="position:relative;top:-0.1rem;right:0;margin-left:0.25rem;">
            <span class="help-icon">?</span>
            <span class="help-text">
              Set to zero days to send immediately. If this is the first drip email in the series, this email will be sent after newsletter subscription is confirmed.
            </span>
          </span>
        </p>

        <p>
          <label>Status</label>
          <select name="status" id="drip-status-select">
            <option value="draft">Draft</option>
            <option value="active">Active</option>
          </select>
        </p>

        <p>
          <input type="submit" class="btn form-btn-half" onClick={save} value="Save Changes" />
          <input type="submit" class="btn btn-gray form-btn-half modal-close" onClick={closeModal} value="Cancel" />
        </p>
      </form>
    </Modal>
  );
}

export default function(props) {
  const emptyDrip = {
    uuid: null,
    subject: "",
    body: "",
    last_sent_at: null,
    recipients: 0,
    days: 7,
    status: "draft",
    newsletter: {
      code: null,
      name: ""
    }
  };
  const [state, setState] = createState({
    drip: undefined,
    isNew: props.uuid === "new",
    unsaved: false
  });
  const { RecipientsModal, openRecipientsModal } = createRecipientsModal(props.uuid);
  const { NewsletterAssetsModal, openNewsletterAssetsModal, setNewsletterAssetCode } = 
    createNewsletterAssetsModal();
  var mde;

  const page = {
    setDrip: (attrs) => {
      const subject = queryJson("#drip-edit-form", ["subject"]).subject;
      const body = mde.value();
      setState({ drip: Object.assign({}, state.drip, { subject, body }, attrs) });
    }
  };

  function initEditor() {
    mde = new EasyMDE({
      element: document.getElementById("email-body"),
      autoDownloadFontAwesome: false,
      toolbar: false,
      spellChecker: false,
      status: false,
      initialValue: state.drip.body,
      autofocus: !state.isNew
    });
    mde.codemirror.on("change", checkUnsaved);
    document.querySelectorAll(".EasyMDEContainer")[0].classList.add("form-email-body");
    if (state.isNew) { document.getElementById("drip-email").focus(); }
  }

  function checkUnsaved() {
    if (state.drip === undefined) { return; }
    const subjectUnsaved = state.drip.subject !== queryInput("#drip-edit-form [name=subject]").value;
    const bodyUnsaved = mde === undefined ? true : state.drip.body !== mde.value();
    setState({ unsaved: subjectUnsaved || bodyUnsaved });
  }

  function submit(e) {
    e.preventDefault();
    if (e.target.hasAttribute("disabled")) { return; }
    saveChanges();
  }

  function beforeSend() {
    if (state.unsaved) {
      return saveChanges();
    } else {
      return Promise.resolve();
    }
  }

  function saveChanges() {
    const json = queryJson("#drip-edit-form", ["subject"], {
      body: mde.value(),
      days: state.drip.days,
      status: state.drip.status,
      newsletter_code: state.drip.newsletter.code
    });

    if (state.isNew) {
      return DripManager.create(json).then((drip) => {
        navigate(`/admin/drips/${drip.uuid}`);
        setState({ unsaved: false });
      });
    } else {
      return DripManager.update(state.drip.uuid, json).then(() => {
        setState({ unsaved: false });
      });
    }
  }

  function sendPreview(e) {
    e.preventDefault();
    beforeSend().then(() => {
      DripManager.preview(state.drip.uuid).then((response) => {
        notify(`A preview email will be sent to ${response.email} shortly.`, { icon: "send", autoclose: true });
      });
    });
  }

  onMount(() => {
    document.body.classList.add("full");
    if (state.isNew) {
      const code = getQueryValue("newsletter");
      if (!code) { return; } // TODO: 404 here

      setNewsletterAssetCode(code);
      NewsletterManager.get(code).then((newsletter) => {
        const drip = Object.assign({}, emptyDrip, { newsletter });
        setState({ drip });
        initEditor();
      });
    } else {
      DripManager.get(props.uuid).then((drip) => {
        setState({ drip });
        initEditor();
        setNewsletterAssetCode(drip.newsletter.code);
      });
    }
  });

  onCleanup(() => {
    document.body.classList.remove("full");
  });

  return (
    <>
      <Show when={state.drip === undefined}>
        <Loading />
      </Show>
      <Show when={state.drip !== undefined}>
        <div class="split">
          <div class="split-preview hidden"></div>
          <div class="split-edit split-wide">
            <div class="split-menu-left">
              <Link href={`/admin/newsletters/${state.drip.newsletter.code}/drips`} class="split-menu-link"><ChevronLeft /> {state.drip.newsletter.name}</Link>
              <span class="slash">/</span>
              <a class="split-stat" href="#" onClick={modalClick("#drip-settings-modal")}>
                {num(state.drip.days)} {pluralize(state.drip.days, "Day")}
              </a>
              <span class="slash">/</span>
              <a class="split-stat" href="#" onClick={modalClick("#drip-settings-modal")}>
                {capitalize(state.drip.status)}
              </a>
              <Show when={state.drip.recipients > 0}>
                <span class="slash">/</span>
                <a class="split-stat" href="#" onClick={openRecipientsModal}>
                  {num(state.drip.recipients)} {state.drip.recipients === 1 ? "Recipient" : "Recipients"}
                </a>
              </Show>
            </div>
            <div class="menu split-menu-right">
              <span class={`split-stat fade ${state.unsaved ? "fade-in" : "fade-out"}`}>Unsaved</span>
              <a class="btn btn-dropdown btn-small" onClick={menuClick("#drip-menu")}>Actions <ChevronDown /></a>
              <Menu id="drip-menu" style="width:10rem;top:1.65rem;right:0;left:auto">
                <Show when={state.unsaved}>
                  <li><a href="#" onClick={submit}>Save Changes</a></li>
                </Show>
                <Show when={!state.unsaved}>
                  <li><a href="#" onClick={(e) => e.preventDefault()} disabled>Save Changes</a></li>
                </Show>
                <li><a href="#" onClick={openNewsletterAssetsModal}>Upload Files</a></li>
                <Show when={!state.isNew}>
                  <li><a href="#" onClick={sendPreview}>Send Preview</a></li>
                  <li><a href="#" onClick={modalClick("#drip-delete-modal")}>Delete Drip</a></li>
                </Show>
              </Menu>
            </div>
            <div class="split-editor">
              <form class="form" id="drip-edit-form">
                <p>
                  <input type="text" value={state.drip.subject} onInput={checkUnsaved} placeholder="Subject" class="form-email-subject" name="subject" id="drip-email" />
                </p>
                <p>
                  <textarea id="email-body" name="body" class="form-email-body" placeholder="Body">{state.drip.body}</textarea>
                </p>
              </form>
            </div>
          </div>
        </div>
        <SettingsModal drip={state.drip} page={page} />
        <Show when={!state.isNew}>
          <DeleteModal drip={state.drip} />
          <RecipientsModal />
        </Show>
        <NewsletterAssetsModal />
      </Show>
    </>
  );
}
