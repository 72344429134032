import { createState } from "solid-js";
import { Show, For } from "solid-js/web";
import { Link, navigate } from "../components/router";
import { Modal, openModal, closeModal } from "../components/modal";
import ResourceManager from "../models/resource-manager";
import LoadSpin from "../icons/load-spin";
import { debounce } from "../utils/helper";
import Fuse from "fuse.js";

const fuseOptions = {
  threshold: 0.4,
  keys: ["search_term"]
};

function Loading() {
  return (
    <div class="loading">
      <LoadSpin />
    </div>
  );
}

function EmptyResources(props) {
  return (
    <div class="empty">
      <img src={`/assets/img/${props.mode === "compose" ? "newsletter" : "product"}.svg`} />
      <h1>No {props.mode === "compose" ? "Newsletters" : "Products"}</h1>
      <p>
        Once a {props.mode === "compose" ? "newsletter" : "product"} is added, you'll be able to {props.mode === "compose" ? "compose a message" : "upload a file"}.
      </p>
    </div>
  );
}

function FoundEmptyResources(props) {
  return (
    <div class="empty">
      <img src={`/assets/img/${props.mode === "compose" ? "newsletter" : "product"}.svg`} />
      <h1>No Matches</h1>
      <p>Nothing matching your search was found.</p>
    </div>
  );
}

export function createQuickModal() {
  const [state, setState] = createState({ resources: undefined, newsletter: undefined, mode: "compose", isEmpty: false });
  const fuse = new Fuse([], fuseOptions);
  let collection = [];

  function openQuickModal(e, mode) {
    openModal("#quick-modal", e);
    ResourceManager.list().then(function(resources) {
      const modeTypes = mode === "compose" ? ["Newsletter", "Drip", "Broadcast"] : ["Product", "Asset"];
      collection = resources.filter(resource => modeTypes.includes(resource.type));
      fuse.setCollection(collection);

      const input = document.getElementById("quick-modal-input");
      if (mode !== state.mode && input) {
        input.value = "";
        setState({ resources: collection.map((item) => ({ item })), mode: mode, isEmpty: collection.length === 0 });
      } else if (input && input.value.replace(/\s/g, "") !== "") {
        setState({ resources: fuse.search(input.value), mode: mode, isEmpty: collection.length === 0 });
      } else {
        setState({ resources: collection.map((item) => ({ item })), mode: mode, isEmpty: collection.length === 0 });
      }

      setTimeout(() => {
        const el = document.getElementById("quick-modal-input");
        if (el && el.value) { el.select(); }
        else if (el) { el.focus(); }
      }, 50);
    });
  }

  function clickResource(e, resource) {
    e.preventDefault();
    closeModal();
    if (resource.type === "Newsletter") {
      setState({ newsletter: resource });
      openModal("#quick-newsletter-modal");
    } else if (resource.type === "Drip") {
      navigate(`/admin/drips/${resource.uuid}`);
    } else if (resource.type === "Broadcast") {
      navigate(`/admin/broadcasts/${resource.uuid}`);
    } else if (resource.type === "Product") {
      navigate(`/admin/products/${resource.code}/assets?upload`, { force: true });
    } else if (resource.type === "Asset") {
      navigate(`/admin/products/${resource.code}/assets?asset=${resource.uuid}`, { force: true });
    }
  }

  function clickBack(e) {
    e.preventDefault();
    closeModal();
    openModal("#quick-modal");
  }

  function addBroadcast(e) {
    e.preventDefault();
    closeModal();
    navigate(`/admin/broadcasts/new?newsletter=${state.newsletter.code}`);
  }

  function addDrip(e) {
    e.preventDefault();
    closeModal();
    navigate(`/admin/drips/new?newsletter=${state.newsletter.code}`);
  }

  const search = debounce(function (e) {
    const searchTerm = e.target.value;
    if (searchTerm.replace(/\s/g, "") === "") {
      setState({ resources: collection.map((item) => ({item})) });
    } else {
      setState({ resources: fuse.search(searchTerm) });
    }
  }, 50);

  function QuickModal() {
    return (
      <>
        <Modal id="quick-modal" class="modal-long modal-wide">
          <Show when={state.resources === undefined}>
            <Loading />
          </Show>
          <Show when={state.resources !== undefined}>
            <Show when={state.isEmpty}>
              <EmptyResources mode={state.mode} />
            </Show>
            <Show when={!state.isEmpty}>
              <form class="form" onSubmit={(e) => e.preventDefault()}>
                <p>
                  <input id="quick-modal-input" type="search" placeholder={`Where to ${state.mode}?`} autofocus onInput={search} />
                </p>
              </form>
              <Show when={state.resources.length > 0}>
                <div class="modal-overflow">
                  <table>
                    <tbody>
                      <For each={state.resources}>
                        {resource =>
                          <tr>
                            <td>
                              <a href="#" onClick={(e) => clickResource(e, resource.item)}>{resource.item.search_term}</a>
                              {/*
                              <Show when={resource.item.type === "Newsletter"}>
                                <span class="suffix">(Add Broadcast or Drip)</span>
                              </Show>
                              <Show when={resource.item.type === "Product"}>
                                <span class="suffix">(Add Asset)</span>
                              </Show>
                              */}
                            </td>
                            <td class="align-right">
                              <span class={`resource resource-${resource.item.type.toLowerCase()}`}>{resource.item.type}</span>
                            </td>
                          </tr>
                        }
                      </For>
                    </tbody>
                  </table>
                </div>
              </Show>
              <Show when={state.resources.length === 0}>
                <FoundEmptyResources mode={state.mode} />
              </Show>
            </Show>
          </Show>
        </Modal>
        <Modal id="quick-newsletter-modal" class="modal-long modal-wide">
          <span class="modal-back-arr" onClick={clickBack}>&larr;</span>
          <Show when={state.newsletter !== undefined}>
            <h3>Which would you like to compose for <b>{state.newsletter.name}</b>?</h3>
            <a href="#" onClick={addBroadcast} class="quick-newsletter-broadcast">
              <img src="/assets/img/broadcast.svg" />
              Broadcast
            </a>
            <a href="#" onClick={addDrip} class="quick-newsletter-drip">
              <img src="/assets/img/drip.svg" />
              Drip
            </a>
            <div class="clear"></div>
          </Show>
        </Modal>
      </>
    );
  }

  return [openQuickModal, QuickModal];
}
