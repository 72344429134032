import { createEffect, createSignal, createState } from "solid-js";
import { Show } from "solid-js/web";
import { Router, Route, Link, navigate } from "../components/router";
import { Menu, menuClick } from "../components/menu";
import { Modal, modalClick, closeModal } from "../components/modal";
import ProductEditModal from "../components/product-edit-modal";
import ProductPurchases from "../components/product-purchases";
import ProductAssets from "../components/product-assets";
import ProductOptions from "../components/product-options";
import ProductInstall from "../components/product-install";
import ProductManager from "../models/product-manager";
import OptionManager from "../models/option-manager";
import ChevronDown from "../icons/chevron-down";
import Stats from "../components/stats";
import { dollars, num } from "../utils/format";
import { previewBuy, previewRecover } from "../utils/preview";

function ProductStats(props) {
  return (
    <Stats
      options={[
        {key: "revenue_total", icon: "bank", label: "Total Revenue", value: dollars(props.product.stats["revenue_total"])},
        {key: "revenue_30d", icon: "wallet", label: "30d Revenue", value: dollars(props.product.stats["revenue_30d"])},
        {key: "revenue_7d", icon: "coins", label: "7d Revenue", value: dollars(props.product.stats["revenue_7d"])},
        {key: "sales_total", icon: "cash-register-alt", label: "Total Sales", value: num(props.product.stats["sales_total"])},
        {key: "sales_30d", icon: "invoice", label: "30d Sales", value: num(props.product.stats["sales_30d"])},
        {key: "sales_7d", icon: "receipt", label: "7d Sales", value: num(props.product.stats["sales_7d"])},
      ]}
      defaults={["revenue_total", "revenue_30d", "revenue_7d", "sales_total"]}
      lsKey="stats:product"
    />
  );
}

function deleteProduct(code) {
  const response = ProductManager.destroy(code);
  response.then(function() {
    navigate("/admin/products");
  });
}

function DeleteModal(props) {
  return (
    <Modal id="product-delete-modal">
      <h2>Delete Product</h2>
      <p>
        Are you sure you want to delete <b>{props.product.name}</b>? 
        Deleting a product will remove customer's access to downloads.
      </p>
      <p>
        <a href="#" class="btn btn-red" onClick={() => { deleteProduct(props.product.code) }}>Confirm Deletion</a>
        <a href="#" class="btn btn-gray modal-close" onClick={closeModal} style="margin-left:1rem">Cancel</a>
      </p>
    </Modal>
  );
}

export default function(props) {
  const code = props.code;
  const [state, setState] = createState({ product: null });

  function refresh() {
    const productResponse = ProductManager.get(code, true);
    productResponse.then(function(product) {
      setState("product", product);
    });
  }
  refresh();

  function buildPreviewAssets() {
    return OptionManager.list(state.product.code, false, true).then((response) => {
      const previewAssets = {};
      response.options.forEach((option) => {
        const assets = [];
        option.assets.forEach((asset) => {
          assets.push({
            name: asset.name,
            filename: asset.url ? null : asset.filename,
            url: asset.url ? asset.url : `#${asset.filename}`,
            url_type: asset.url_type,
            icon: asset.icon
          });
        });
        previewAssets[option.code] = assets;
      });
      return { previewAssets, previewOption: response.options[0] };
    });
  }

  function onPreviewBuy(e) {
    e.preventDefault();
    buildPreviewAssets().then(({ previewAssets }) => {
      previewBuy(state.product.code, previewAssets);
    });
  }

  function onPreviewRecover(e) {
    e.preventDefault();
    buildPreviewAssets().then(({ previewAssets, previewOption }) => {
      previewRecover(state.product.code, previewAssets, previewOption);
    });
  }

  return (
    <Show when={state.product}>
      <h1>{state.product.name}</h1>
      <div class="menu menu-up">
        <a class="btn btn-dropdown btn-small" onClick={menuClick("#product-menu")}>Actions <ChevronDown /></a>
        <Menu id="product-menu" style="width:10rem;top:1.65rem">
          <li><a href="#" onClick={modalClick("#product-edit-modal")}>Edit Product</a></li>
          {/*
          <li><a href="#" onClick={onPreviewBuy}>Preview Purchase</a></li>
          <li><a href="#" onClick={onPreviewRecover}>Preview Recover</a></li>
          */}
          <li><a href="#" onClick={modalClick("#product-delete-modal")}>Delete Product</a></li>
        </Menu>
      </div>

      <ProductStats product={state.product} />

      <Router>
        <nav class="tabs">
          <Link selectable href={`/admin/products/${state.product.code}`}>Purchases</Link>
          <Link selectable href={`/admin/products/${state.product.code}/assets`}>Assets</Link>
          <Link selectable href={`/admin/products/${state.product.code}/options`}>Options</Link>
          <Link selectable href={`/admin/products/${state.product.code}/install`}>Install</Link>
        </nav>

        <Route key="product-purchases" path={`/admin/products/${state.product.code}`} component={ProductPurchases} props={{ product: state.product, onUpdate: refresh }} />
        <Route key="product-assets" path={`/admin/products/${state.product.code}/assets`} component={ProductAssets} props={{ product: state.product }} />
        <Route key="product-options" path={`/admin/products/${state.product.code}/options`} component={ProductOptions} props={{ product: state.product }} />
        <Route key="product-install" path={`/admin/products/${state.product.code}/install`} component={ProductInstall} props={{ product: state.product }} />
      </Router>

      <DeleteModal product={state.product} />
      <ProductEditModal product={state.product} onSave={refresh} />
    </Show>
  );
}
