function onEscKey(e) {
  if (e.key === "Escape") {
    closeModal();
  }
}
export function modalClick(selector) {
  return openModal.bind(null, selector);
}

export function openModal(selector, e = null) {
  if (e) { e.preventDefault(); }
  const modal = document.querySelector(selector);
  if (!modal || modal.classList.contains("active")) { return; }
  modal.classList.add("active");

  const autofocus = document.querySelector(selector + " [autofocus]") as HTMLInputElement;
  if (autofocus) { autofocus.focus(); }

  document.addEventListener("keyup", onEscKey);
}

export function closeModal(e = null) {
  if (e) {
    const classNames = e.target.classList;
    if (classNames.contains("active") || classNames.contains("modal-close")) {
      // background or X was clicked (instead of any element in modal propagating an event)
      e.preventDefault();
      closeModal();
    }
  } else {
    document.querySelectorAll(".modal.active").forEach((modal) => {
      modal.classList.remove("active");
    });
    document.removeEventListener("keyup", onEscKey);
  }
}

export function Modal(props) {
  if (props["class"]) {
    props["class"] = `${props["class"]} modal`;
  } else {
    props["class"] = "modal";
  }

  return (
    <div onClick={closeModal} {...props}>
      <div class="modal-box">
        {props.children}
        <span class="modal-close modal-close-x" onClick={closeModal}>&times;</span>
      </div>
    </div>
  );
}
