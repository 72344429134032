import Request from "../utils/request";

const ENV_KEY = "boutique:env";

async function fetchEnv() {
  return await Request.get("/api/admin/env");
}

export async function reset() {
  const env = await fetchEnv();
  window.localStorage.setItem(ENV_KEY, JSON.stringify(env));
}

export async function get() {
  if (!get.cachedEnv) {
    const env = window.localStorage.getItem(ENV_KEY);
    if (env) {
      get.cachedEnv = JSON.parse(env);
    } else {
      get.cachedEnv = fetchEnv();
      window.localStorage.setItem(ENV_KEY, JSON.stringify(get.cachedEnv));
    }
  }
  return get.cachedEnv;
}

export default {
  reset,
  get
};
