import Request from "../utils/request";

export async function get(code: string, stats: boolean = false) {
  const includes = stats ? "?includes=stats" : "";
  const url = `/api/admin/products/${code}${includes}`;
  const response = await Request.get(url);
  return response.product;
}

export async function list(stats: boolean = false) {
  const includes = stats ? "?includes=stats" : "";
  const url = `/api/admin/products${includes}`;
  const response = await Request.get(url);
  return response.products;
}

export async function update(code: string, json: object) {
  const response = await Request.patch(`/api/admin/products/${code}`, json);
  return response.product;
}

export async function create(json: object) {
  const response = await Request.post(`/api/admin/products`, json);
  return response.product;
}

export async function destroy(code: string) {
  const response = await Request.destroy(`/api/admin/products/${code}`);
  return response.product;
}

export default {
  get,
  list,
  update,
  create,
  destroy
};
