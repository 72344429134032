import Request from "../utils/request";

export async function list() {
  const response = await Request.get("/api/admin/resources");
  return response.resources;
}

export default {
  list
};
