import Request from "../utils/request";

export async function get() {
  const url = "/api/admin/dashboard";
  const response = await Request.get(url);
  return response;
}

export default {
  get
}
