import { createState, onMount } from "solid-js";
import { For, Show } from "solid-js/web";
import { Modal, openModal, modalClick, closeModal } from "../components/modal";
import NewsletterAssetManager from "../models/newsletter-asset-manager";
import currentSession from "../models/current-session";
import LoadSpin from "../icons/load-spin";
import CloudUpload from "../icons/cloud-upload";
import Clipboard from "../icons/clipboard";
import Dropzone from "dropzone";

function EmptyAssets() {
  return (
    <div class="empty empty-modal-small">
      <img src="/assets/img/asset.svg" />
      <h1>No Files</h1>
      <p>
        Add images, documents, and other files here. They'll be available at a public URL, so you can embed or link to them in your emails.
      </p>
    </div>
  );
}

function LoadingAssets() {
  return (
    <div class="loading loading-modal">
      <LoadSpin />
    </div>
  );
}

function UploadForm(props) {
  let dropzone;
  const [state, setState] = createState({ processing: false });

  function getUrl() {
    return `/api/admin/newsletter_assets/files?newsletter_code=${props.newsletterCode}`;
  }

  function onStart() {
    setState({ processing: true });
  }

  function onStop() {
    setState({ processing: false });
    props.refreshAssets();
  }

  onMount(() => {
    dropzone = new Dropzone("#upload-form .form-dropzone-well", {
      url: getUrl,
      autoProcessQueue: true,
      createImageThumbnails: false,
      uploadMultiple: false,
      parallelUploads: 1,
      paramName: "file",
      headers: {
        Authorization: `Bearer ${currentSession.accessToken}`
      },
      ignoreHidenFiles: false,
      addRemoveLinks: false,
      previewsContainer: "#upload-form .form-dropzone-previews",
      clickable: "#upload-form .form-dropzone-click",
    });
    dropzone.on("addedfile", onStart);
    dropzone.on("queuecomplete", onStop);
  });

  return (
    <form id="upload-form" class={`form form-modal form-dropzone ${props.hidden ? "hidden" : ""}`}>
      <div class="form-dropzone-well">
        <p class="form-dropzone-instruct">
          <span class={`${props.processing ? "hidden" : ""}`}>
            <CloudUpload /> <a href="#" class="form-dropzone-click">Upload files</a> or drag them here
          </span>
          <span class={`${props.processing ? "" : "hidden"}`}>
            <LoadSpin /> Uploading files...
          </span>
        </p>
      </div>
      <div class="form-dropzone-previews hidden"></div>
    </form>
  );
}

export function createNewsletterAssetsModal() {
  const [state, setState] = createState({
    assets: undefined,
    newsletterCode: undefined
  });

  function refreshAssets(e = null) {
    if (e) { e.preventDefault(); }
    if (!state.newsletterCode) { return; }

    NewsletterAssetManager.list(state.newsletterCode).then((assets) => {
      setState({ assets });
    });
  }

  function copyUrl(e, uuid) {
    e.preventDefault();
    const filenameEl = document.getElementById(`newsletter-asset-filename-${uuid}`);
    const formEl = document.getElementById(`newsletter-asset-form-${uuid}`);
    const inputEl = formEl.querySelector("input[type=text]");
    const copiedEl = filenameEl.querySelector("span");

    filenameEl.classList.add("hidden");
    formEl.classList.remove("hidden");
    inputEl.select();
    document.execCommand("copy");
    formEl.classList.add("hidden");
    filenameEl.classList.remove("hidden");

    copiedEl.classList.remove("hidden-visibility");
    setTimeout(() => {
      copiedEl.classList.add("hidden-visibility");
    }, 1000);
  }

  function deleteFile(e, asset) {
    e.preventDefault();
    setState({ deleteAsset: asset });
    closeModal();
    openModal("#newsletter-assets-delete-modal");
  }

  function confirmDeleteFile(e) {
    e.preventDefault();
    NewsletterAssetManager.destroy(state.deleteAsset.uuid).then(() => {
      closeModal();
      setState({ deleteAsset: undefined });
      refreshAssets();
      openModal("#newsletter-assets-modal");
    });
  }

  function cancelDeleteFile(e) {
    e.preventDefault();
    closeModal();
    setTimeout(() => {
      openModal("#newsletter-assets-modal");
    }, 1);
  }

  return {
    openNewsletterAssetsModal: function(e) {
      e.preventDefault();
      if (!state.newsletterCode) { return; }

      openModal("#newsletter-assets-modal");
      refreshAssets();
    },

    setNewsletterAssetCode: function(code) {
      setState({ newsletterCode: code });
    },

    NewsletterAssetsModal: function() {
      return (
        <>
          <Modal id="newsletter-assets-modal" class="modal-long modal-wide">
            <UploadForm
              newsletterCode={state.newsletterCode}
              hidden={!state.newsletterCode || state.assets === undefined}
              refreshAssets={refreshAssets}
            />
            <Show when={state.assets && state.assets.length >= 1}>
              <table>
                <thead>
                  <tr>
                    <th>Files</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <For each={state.assets}>
                    {asset =>
                      <tr>
                        <td>
                          <span id={`newsletter-asset-filename-${asset.uuid}`}>
                            {asset.filename}
                            <span class="suffix hidden-visibility" style="margin-left:0.5rem">
                              copied!
                            </span>
                          </span>
                          <form class="form hidden" id={`newsletter-asset-form-${asset.uuid}`}>
                            <input type="text" value={asset.url} readonly />
                          </form>
                        </td>
                        <td class="table-col-actions" style="width:35%">
                          <a href={asset.url} class="btn btn-small btn-small-narrow" onClick={(e) => copyUrl(e, asset.uuid)} style="padding-left:0.3rem;"><Clipboard /> Copy URL</a>
                          &nbsp;
                          <a href="#" class="btn btn-small btn-small-narrow btn-gray" onClick={(e) => deleteFile(e, asset)}>Delete</a>
                        </td>
                      </tr>
                    }
                  </For>
                </tbody>
              </table>
            </Show>
            <Show when={state.assets && state.assets.length <= 0}>
              <EmptyAssets />
            </Show>
            <Show when={state.assets === undefined}>
              <LoadingAssets />
            </Show>
          </Modal>
          <Show when={state.deleteAsset}>
            <Modal id="newsletter-assets-delete-modal">
              <div class="form">
                <h2>Delete File</h2>
                <p>
                  Are you sure you want to remove <b>{ state.deleteAsset.filename }</b>? 
                </p>
                <p>
                  <a href="#" class="btn btn-red form-btn-half" onClick={confirmDeleteFile}>Confirm Deletion</a>
                  <a href="#" class="btn btn-gray modal-close" onClick={cancelDeleteFile}>Cancel</a>
                </p>
              </div>
            </Modal>
          </Show>
        </>
      );
    }
  };
}
