import { createEffect, createState } from "solid-js";
import { For } from "solid-js/web";
import { default as iconSvg } from "../utils/icon";

const icons = [
  "file",
  "files_queue",
  "file_zip",
  "file_text",
  "file_rich_text",
  "file_music",
  "file_video",
  "file_photo",
  "file_vector",
  "file_database",
  "file_program",
  "file_terminal",
  "file_script",
  "file_presentation",
  "file_cloud",
  "cloud",
  "folder_open",
  "book",
  "book_library",
  "book_open_text",
  "book_open",
  "notes",
  "archive",
  "bookmark",
  "link",
  "paperclip",
  "picture",
  "music",
  "film",
  "video_camera",
  "code",
  "terminal",
  "blackboard",
  "calendar",
  "table",
  "ticket",
  "envelope",
  "chat_conversation",
  "receipt",
  "invoice",
  "tag",
  "tags",
  "stats",
  "stats_bar",
  "stats_circle",
  "computer_pc_tower",
  "notebook",
  "tablet",
  "mobile_phone",
  "star",
  "heart",
  "gift"
];

let getSelected;

export function getSelectedIcon() {
  return getSelected();
}

export function IconEditor(props) {
  const [state, setState] = createState({ selected: props.icon });
  let lastUuid = props.uuid;

  function selectIcon(icon, e) {
    e.preventDefault();
    setState({ selected: icon });
  }

  getSelected = function() {
    return state.selected;
  }

  createEffect(() => {
    if (lastUuid !== props.uuid) {
      setState({ selected: props.icon });
    }
  });

  return (
    <span class="icon-editor">
      {icons.map((icon) =>
        <a class={`icon-editor-item ${state.selected === icon ? "selected" : ""}`} onClick={selectIcon.bind(null, icon)}>
          {iconSvg(icon)}
        </a>
      )}
    </span>
  );
}
