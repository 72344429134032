import moment from "moment";

export function capitalize(str: string | undefined) : string {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return "";
  }
}

export function pluralize(count: number, str: string, pluralStr: string | undefined) : string {
  if (count === 1) {
    return str;
  } else if (typeof(pluralStr) !== "undefined") {
    return pluralStr;
  } else {
    return `${str}s`;
  }
}

export function num(int: number | undefined) : string {
  if (typeof(int) !== "undefined" && int !== null) {
    return Number(int).toLocaleString();
  } else {
    return "";
  }
}

export function pct(int: number | undefined) : string {
  if (typeof(int) !== "undefined" && int !== null) {
    return `${num(int)}%`;
  } else {
    return "";
  }
}

export function dollars(cents: number | undefined, prefix: string = "$") : string {
  if (typeof(cents) !== "undefined" && cents !== null) {
    return `${prefix}${Math.round(cents / 100).toLocaleString()}`;
  } else {
    return "";
  }
}

export function money(cents: number | undefined, prefix: string = "$") : string {
  if (typeof(cents) !== "undefined" && cents !== null) {
    const decimal = cents % 100;
    const decimalStr = decimal < 10 ? `.0${decimal}` : `.${decimal}`;
    return `${prefix}${Math.floor(cents / 100).toLocaleString()}${decimalStr}`;
  } else {
    return "";
  }
}

export function date(str: string | undefined) : string {
  if (str) {
    return moment(str).format("l");
  } else {
    return "";
  }
}

export function datetime(str: string | undefined) : string {
  if (str) {
    return moment(str).format("l LT");
  } else {
    return "";
  }
}

export function status(statusStr: string | undefined) {
  if (statusStr) {
    return (
      <span class={`status status-${statusStr}`}>{capitalize(statusStr)}</span>
    );
  } else {
    return "";
  }
}

export function nameCode(name: string | undefined) : string {
  if (name) {
    return name.trim().replace(/\s+/g, "-").replace(/[^a-zA-Z0-9-_]/g, "").toLowerCase();
  } else {
    return "";
  }
}

export function tzLabel(name: string) : string {
  if (name === "local") {
    return "Local";
  } else {
    return name.replace(/_/g, " ");
  }
}

export function linkText(text : string | undefined) : string {
  if (typeof(text) === "undefined" || text === null || text.trim() === "") {
    return "None";
  } else {
    return truncate(text);
  }
}

export function truncate(text : string | undefined, length : number = 50) : string {
  if (typeof(text) === "undefined" || text === null || text.trim() === "") {
    return "";
  } else {
    const str = text.trim();
    if (str.length <= length) {
      return str;
    } else {
      return `${str.substring(0, length)}...`;
    }
  }
}
