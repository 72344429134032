import { onMount, createEffect, onCleanup, createState } from "solid-js";
import { Show } from "solid-js/web";
import { Link, navigate, getQueryValue } from "../components/router";
import { Menu, menuClick } from "../components/menu";
import { Modal, modalClick, closeModal } from "../components/modal";
import { createRecipientsModal } from "../components/recipients-modal";
import { createNewsletterAssetsModal } from "../components/newsletter-assets-modal";
import LoadSpin from "../icons/load-spin";
import ChevronDown from "../icons/chevron-down";
import ChevronLeft from "../icons/chevron-left";
import BroadcastManager from "../models/broadcast-manager";
import NewsletterManager from "../models/newsletter-manager";
import { queryInput, queryJson } from "../utils/form";
import { date, num, pluralize } from "../utils/format";
import { notify } from "../utils/notify";
import EasyMDE from "../vendor/easymde";

function Loading() {
  return (
    <div class="loading loading-full"><LoadSpin /></div>
  );
}

function SendModal(props) {
  function send(e) {
    e.preventDefault();
    props.beforeSend().then(() => {
      BroadcastManager.send(props.broadcast.uuid).then(() => {
        closeModal();
        notify("Broadcast has been queued. It will be sent to subscribers shortly.", { icon: "send", autoclose: true });
      });
    });
  }

  return (
    <Modal id="broadcast-send-modal">
      <div class="form">
        <h2>Send Broadcast</h2>

        <Show when={props.broadcast.recipients === props.broadcast.recipients_total }>
          <Show when={props.broadcast.recipients_total === 0}>
            <p>
              This newsletter currently has no subscribers.
            </p>
          </Show>
          <Show when={props.broadcast.recipients_total !== 0}>
            <p>
              This broadcast has already been sent to all 
              <b>{props.broadcast.recipients_total} {pluralize(props.broadcast.recipients_total, "subscriber")}</b>. 
            </p>
          </Show>
          <p>
            <a href="#" class="btn btn-gray modal-close" onClick={closeModal}>Cancel</a>
          </p>
        </Show>

        <Show when={props.broadcast.recipients !== props.broadcast.recipients_total }>
          <Show when={props.broadcast.recipients !== 0}>
            <p>
              This broadcast has been sent to <b>{props.broadcast.recipients} out of {props.broadcast.recipients_total}</b> {pluralize(props.broadcast.recipients_total, "subscriber")} so far. 
              It was last sent on <b>{date(props.broadcast.last_sent_at)}</b>. Send now to the remaining subscribers.
            </p>
          </Show>
          <Show when={props.broadcast.recipients === 0}>
            <p>
              Send now to <b>{props.broadcast.recipients_total} {pluralize(props.broadcast.recipients_total, "subscriber")}</b>.
            </p>
          </Show>
          <p>
            <Show when={props.unsaved}>
              <a href="#" class="btn form-btn-half" onClick={send}>Save &amp; Send Broadcast</a>
            </Show>
            <Show when={!props.unsaved}>
              <a href="#" class="btn form-btn-half" onClick={send}>Send Broadcast</a>
            </Show>
            <a href="#" class="btn btn-gray modal-close" onClick={closeModal}>Cancel</a>
          </p>
        </Show>
      </div>
    </Modal>
  );
}

function DeleteModal(props) {
  function destroy(e) {
    e.preventDefault();
    BroadcastManager.destroy(props.broadcast.uuid).then(() => {
      navigate(`/admin/newsletters/${props.broadcast.newsletter.code}/broadcasts`);
    });
  }

  return (
    <Modal id="broadcast-delete-modal">
      <div class="form">
        <h2>Delete Broadcast</h2>
        <p>
          Are you sure you want to remove <b>{ props.broadcast.subject }</b>? 
        </p>
        <p>
          <a href="#" class="btn btn-red form-btn-half" onClick={destroy}>Confirm Deletion</a>
          <a href="#" class="btn btn-gray modal-close" onClick={closeModal}>Cancel</a>
        </p>
      </div>
    </Modal>
  );
}

export default function(props) {
  const emptyBroadcast = {
    uuid: null,
    subject: "",
    body: "",
    last_sent_at: null,
    recipients: 0,
    newsletter: {
      code: null,
      name: ""
    }
  };
  const [state, setState] = createState({
    broadcast: undefined,
    isNew: props.uuid === "new",
    unsaved: false
  });
  const { RecipientsModal, openRecipientsModal } = createRecipientsModal(props.uuid);
  const { NewsletterAssetsModal, openNewsletterAssetsModal, setNewsletterAssetCode } = 
    createNewsletterAssetsModal();
  var mde;

  function initEditor() {
    mde = new EasyMDE({
      element: document.getElementById("email-body"),
      autoDownloadFontAwesome: false,
      toolbar: false,
      spellChecker: false,
      status: false,
      initialValue: state.broadcast.body,
      autofocus: false
    });
    mde.codemirror.on("change", checkUnsaved);
    document.querySelectorAll(".EasyMDEContainer")[0].classList.add("form-email-body");
    document.getElementById("broadcast-email").focus();
  }

  function checkUnsaved() {
    if (state.broadcast === undefined) { return; }
    const subjectUnsaved = state.broadcast.subject !== queryInput("#broadcast-edit-form [name=subject]").value;
    const bodyUnsaved = mde === undefined ? true : state.broadcast.body !== mde.value();
    setState({ unsaved: subjectUnsaved || bodyUnsaved });
  }

  function submit(e) {
    e.preventDefault();
    if (e.target.hasAttribute("disabled")) { return; }
    saveChanges();
  }

  function beforeSend() {
    if (state.unsaved) {
      return saveChanges();
    } else {
      return Promise.resolve();
    }
  }

  function saveChanges() {
    const json = queryJson("#broadcast-edit-form", ["subject"], {
      body: mde.value(),
      newsletter_code: state.broadcast.newsletter.code
    });

    if (state.isNew) {
      return BroadcastManager.create(json).then((broadcast) => {
        navigate(`/admin/broadcasts/${broadcast.uuid}`);
        setState({ unsaved: false });
      });
    } else {
      return BroadcastManager.update(state.broadcast.uuid, json).then(() => {
        setState({ unsaved: false });
      });
    }
  }

  function sendPreview(e) {
    e.preventDefault();
    beforeSend().then(() => {
      BroadcastManager.preview(state.broadcast.uuid).then((response) => {
        notify(`A preview email will be sent to ${response.email} shortly.`, { icon: "send", autoclose: true });
      });
    });
  }

  onMount(() => {
    document.body.classList.add("full");
    if (state.isNew) {
      const code = getQueryValue("newsletter");
      if (!code) { return; } // TODO: 404 here

      setNewsletterAssetCode(code);
      NewsletterManager.get(code).then((newsletter) => {
        const broadcast = Object.assign({}, emptyBroadcast, { newsletter });
        setState({ broadcast });
        initEditor();
      });
    } else {
      BroadcastManager.get(props.uuid).then((broadcast) => {
        setState({ broadcast });
        initEditor();
        setNewsletterAssetCode(broadcast.newsletter.code);
      });
    }
  });

  onCleanup(() => {
    document.body.classList.remove("full");
  });

  return (
    <>
      <Show when={state.broadcast === undefined}>
        <Loading />
      </Show>
      <Show when={state.broadcast !== undefined}>
        <div class="split">
          <div class="split-preview hidden"></div>
          <div class="split-edit split-wide">
            <div class="split-menu-left">
              <Link href={`/admin/newsletters/${state.broadcast.newsletter.code}/broadcasts`} class="split-menu-link"><ChevronLeft /> {state.broadcast.newsletter.name}</Link>
              <Show when={state.broadcast.last_sent_at}>
                <span class="slash">/</span>
                <a class="split-stat" href="#" onClick={openRecipientsModal}>{num(state.broadcast.recipients)} {state.broadcast.recipients === 1 ? "Recipient" : "Recipients"}</a>
              </Show>
            </div>
            <div class="menu split-menu-right">
              <span class={`split-stat fade ${state.unsaved ? "fade-in" : "fade-out"}`}>Unsaved</span>
              <a class="btn btn-dropdown btn-small" onClick={menuClick("#broadcast-menu")}>Actions <ChevronDown /></a>
              <Menu id="broadcast-menu" style="width:10rem;top:1.65rem;right:0;left:auto">
                <Show when={state.unsaved}>
                  <li><a href="#" onClick={submit}>Save Changes</a></li>
                </Show>
                <Show when={!state.unsaved}>
                  <li><a href="#" onClick={(e) => e.preventDefault()} disabled>Save Changes</a></li>
                </Show>
                <li><a href="#" onClick={openNewsletterAssetsModal}>Upload Files</a></li>
                <Show when={!state.isNew}>
                  <li><a href="#" onClick={sendPreview}>Send Preview</a></li>
                  <li><a href="#" onClick={modalClick("#broadcast-send-modal")}>Send Broadcast</a></li>
                  <li><a href="#" onClick={modalClick("#broadcast-delete-modal")}>Delete Broadcast</a></li>
                </Show>
              </Menu>
            </div>
            <div class="split-editor">
              <form class="form" id="broadcast-edit-form">
                <p>
                  <input type="text" value={state.broadcast.subject} onInput={checkUnsaved} placeholder="Subject" class="form-email-subject" name="subject" id="broadcast-email" />
                </p>
                <p>
                  <textarea id="email-body" name="body" class="form-email-body" placeholder="Body">{state.broadcast.body}</textarea>
                </p>
              </form>
            </div>
          </div>
        </div>
        <Show when={!state.isNew}>
          <SendModal broadcast={state.broadcast} beforeSend={beforeSend} unsaved={state.unsaved} />
          <DeleteModal broadcast={state.broadcast} />
          <RecipientsModal />
        </Show>
        <NewsletterAssetsModal />
      </Show>
    </>
  );
}
