import { onCleanup } from "solid-js";
import { navigate } from "../components/router";
import Session from "../models/session";
import LoadSpin from "../icons/load-spin";

function login(e) {
  e.preventDefault();
  const email = document.querySelector("#login-form input[name=email]") as HTMLInputElement;
  const password = document.querySelector("#login-form input[name=password]") as HTMLInputElement;
  const button = document.getElementById("login-form-btn");
  button.disabled = true;
  Session.login(email.value, password.value).then(() => {
    navigate("/admin");
  }).finally(() => {
    button.disabled = false;
  });
}

export default function() {
  document.body.classList.add("bare");
  onCleanup(() => {
    document.body.classList.remove("bare");
  });
  const demo = window.location.hostname === "demo.useboutique.com";

  return (
    <main>
      <h2 class="center">Boutique</h2>

      <form class="form form-bare" id="login-form" onSubmit={login}>
        <p>
          <input name="email" type="email" autofocus required placeholder="Email Address" value={demo ? "demo@useboutique.com" : ""} />
        </p>

        <p>
          <input name="password" type="password" required placeholder="Password" value={demo ? "demo" : ""} />
        </p>

        <p>
          <input type="submit" class="btn" value="Sign In" id="login-form-btn" />
        </p>
      </form>
    </main>
  );
}
