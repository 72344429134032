let count = 0;

export function domId(prefix : string = "id") : string {
  count += 1;
  return `${prefix}-${count}`;
}

export function isBlank(str : string | undefined) : boolean {
  return !str || /^\s*$/.test(str);
}

export function isPresent(str : string | undefined) : boolean {
  return !isBlank(str);
}

export function isNullOrUndefined(obj) {
  return typeof(obj) === "undefined" || obj === null;
}

export function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
