import Request from "../utils/request";

export async function list(newsletterCode: string) {
  const url = `/api/admin/newsletter_assets?newsletter_code=${newsletterCode}`;
  const response = await Request.get(url);
  return response.newsletter_assets;
}

export async function update(uuid: string, json: object) {
  const response = await Request.patch(`/api/admin/newsletter_assets/${uuid}`, json);
  return response.newsletter_asset;
}

export async function destroy(uuid: string) {
  const response = await Request.destroy(`/api/admin/newsletter_assets/${uuid}`);
  return response.newsletter_asset;
}

export default {
  list,
  update,
  destroy
};
