import { createState } from "solid-js";
import { Show } from "solid-js/web";
import LoadSpin from "../icons/load-spin";
import EnvManager from "../models/env-manager";

function Loading() {
  return (
    <div class="loading loading-tab">
      <LoadSpin />
    </div>
  );
}

export default function(props) {
  const envResponse = EnvManager.get();
  const [state, setState] = createState({ env: undefined });
  envResponse.then(function(env) {
    setState({ env });
  });

  return (<>
    <Show when={state.env === undefined}>
      <Loading />
    </Show>

    <Show when={state.env}>
      <h3>Install</h3>

      <div class="docs">
        <p>
          Add this line right before the <code>&lt;/body&gt;</code> closing tag of your website:
        </p>

        <pre><code>
          <span class="docs-tag">&lt;script</span> <span class="docs-id">src</span>=<span class="docs-string">"{state.env.url}/boutique.js"</span><span class="docs-tag">&gt;&lt;/script&gt;</span>
        </code></pre>

        <p>
          Using a bundler? Download the script and add it to your project:
        </p>

        <pre><code>
          curl {state.env.url}/boutique.js &gt; boutique.js
        </code></pre>

        <p>
          To start a subscribe flow, use <code>Boutique.subscribe(<span class="docs-string">"{props.newsletter.code}"</span>)</code>. For example:
        </p>

        <pre><code>
          <span class="docs-tag">&lt;button</span> <span class="docs-id">onclick</span>=<span class="docs-string">"Boutique.subscribe('{props.newsletter.code}');"</span><span class="docs-tag">&gt;</span>Subscribe<span class="docs-tag">&lt;/button&gt;</span>
        </code></pre>
      </div>
    </Show>
  </>);
}
