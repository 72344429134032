import { createSignal } from "solid-js";
import { For, Show } from "solid-js/web";
import { Link, navigate } from "../components/router";
import { modalClick } from "../components/modal";
import ProductEditModal from "../components/product-edit-modal";
import ProductManager from "../models/product-manager";
import ChevronDown from "../icons/chevron-down";
import ChevronLeft from "../icons/chevron-left";
import ChevronRight from "../icons/chevron-right";
import Send from "../icons/send";
import Kiosk from "../icons/kiosk";
import Dollar from "../icons/dollar";
import { money, num, linkText } from "../utils/format";
import icon from "../utils/icon";
import LoadSpin from "../icons/load-spin";

interface Product {
  code: string,
  name: string,
  price: number,
  stats: ProductStats,
  asset_icons: Array<string>
}

interface ProductStats {
  revenue_total: number,
  sales_total: number
}

function EmptyProducts() {
  return (
    <div class="empty">
      <img src="/assets/img/product.svg" />
      <h1>No Products</h1>
      <p>
        Want to sell something? Get started here!
      </p>
      <p>
        <a class="btn" href="#" onClick={modalClick("#product-edit-modal")}>Add Product</a>
      </p>
    </div>
  );
}

export default function() {
  const productResponse = ProductManager.list(true);
  const [products, setProducts] = createSignal(null);
  productResponse.then(function(products) {
    setProducts(products);
  });

  function assetIcons(product) {
    function onlyUnique(value, index, self) { 
      return self.indexOf(value) === index;
    }

    if (!product.asset_icons || product.asset_icons.length === 0) {
      return "None";
    } else {
      const icons = product.asset_icons.filter(onlyUnique);
      return icons.slice(0, 5).map(i => icon(i));
    }
  }

  return (<>
    <Show when={products() && products().length > 0}>
      <h3>Products</h3>

      <div class="menu">
        <a href="#" onClick={modalClick("#product-edit-modal")}>Add Product</a>
      </div>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th class="table-col-assets">Assets</th>
            <th class="align-right">Price</th>
            <th class="align-right">Revenue</th>
            <th class="align-right"># Sales</th>
          </tr>
        </thead>
        <tbody>
          <For each={products()}>
            {(product: Product) =>
              <tr>
                <td><Link href={`/admin/products/${product.code}`}>{linkText(product.name)}</Link></td>
                <td class="table-col-assets">
                  <span class="table-assets">{assetIcons(product)}</span>
                </td>
                <td class="align-right">{money(product.price)}</td>
                <td class="align-right">{money(product.stats.revenue_total)}</td>
                <td class="align-right">{num(product.stats.sales_total)}</td>
              </tr>
            }
          </For>
        </tbody>
      </table>
    </Show>
    <Show when={products() && products().length <= 0}>
      <EmptyProducts />
    </Show>
    <Show when={products() === null}>
      <div class="loading">
        <LoadSpin />
      </div>
    </Show>
    <ProductEditModal />
  </>);
}
