import Request from "../utils/request";

export async function create(productCode: string, optionCodes: Array<string>, json: object) {
  const url = `/api/admin/assets?product_code=${productCode}&option_codes=${optionCodes.join(",")}`
  const response = await Request.post(url, json);
  return response.asset;
}

export async function list(productCode: string, includeOptions = false) {
  const includes = includeOptions ? "&includes=options" : "";
  const url = `/api/admin/assets?product_code=${productCode}${includes}`;
  const response = await Request.get(url);
  return response;
}

export async function update(uuid: string, json: object) {
  const response = await Request.patch(`/api/admin/assets/${uuid}`, json);
  return response.asset;
}

export async function destroy(uuid: string) {
  const response = await Request.destroy(`/api/admin/assets/${uuid}`);
  return response.asset;
}

export default {
  create,
  list,
  update,
  destroy
};
