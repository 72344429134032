import { createEffect, onMount, createState, createContext, useContext, onCleanup } from "solid-js";
import { Show } from "solid-js/web";
import ChevronDown from "../icons/chevron-down";
import { domId } from "../utils/helper";
import { truncate } from "../utils/format";
import { Menu, menuClick } from "../components/menu";

const SelectMenuContext = createContext({ selectMenu: null });
const selectMenus = {};

export function getSelected(id: string) {
  if (selectMenus[id]) {
    return selectMenus[id].getSelected();
  }
}

export function setSelected(id: string, values) {
  if (selectMenus[id]) {
    return selectMenus[id].setSelected(values);
  }
}

export function SelectOption(props) {
  const { selectMenu } = useContext(SelectMenuContext);
  const [state, setState] = createState({ selected: selectMenu.isSelected(props.value) });

  function toggleOption(e) {
    e.preventDefault();
    setState({ selected: !state.selected });
    if (state.selected) {
      selectMenu.addSelected(props.value);
    } else {
      selectMenu.removeSelected(props.value);
    }
  }

  selectMenu.menuItems[props.value] = {
    setSelected: function(value) {
      setState({ selected: value });
    }
  };

  return (
    <li class={`select-menu-item ${state.selected ? "selected" : ""}`} data-value={props.value} data-label={props.children}>
      <a href="#" onClick={toggleOption} class="btn-menu-keep">
        <span class="btn-menu-check">
          <Show when={state.selected}><span>&check;</span></Show>
        </span>
        {props.children}
      </a>
    </li>
  );
}

export function SelectMenu(props) {
  const classNames = (props["class"] || "") + " select-menu";
  const id = props.id || domId("select-menu");
  const [state, setState] = createState({ selected: props.selected || [], label: "None" });

  const selectMenu = {
    id: id,

    menuItems: {},

    isSelected: function(value) {
      return state.selected.includes(value);
    },

    getSelected: function() {
      return state.selected;
    },

    setSelected: function(values) {
      const selectedOptions = document.querySelectorAll(`#${this.id} .select-menu-item.selected a`);
      selectedOptions.forEach((opt) => (opt as HTMLAnchorElement).click());
      values.forEach((value) => {
        selectMenu.menuItems[value].setSelected(true);
      });
      setState({ selected: values });
      setState({ label: selectMenu.getLabel() });
    },

    addSelected: function(value) {
      if (!this.isSelected(value)) {
        const selected = state.selected.slice(0);
        selected.push(value);
        setState({ selected });
      }
    },

    removeSelected: function(value) {
      if (this.isSelected(value)) {
        const selected = state.selected.filter(val => val !== value);
        setState({ selected });
      }
    },

    getOptionLabel: function(value) {
      const option = document.querySelector(`#${this.id} .select-menu-item[data-value="${value}"]`);
      if (option) {
        return option.getAttribute("data-label");
      }
    },

    getLabel: function() {
      const labels = state.selected.map((val) => this.getOptionLabel(val)).filter((val) => val);
      if (labels.length === 0) {
        return "None";
      } else {
        return labels.join(", ");
      }
    }
  };
  selectMenus[id] = selectMenu;

  onCleanup(() => {
    delete selectMenus[id];
  });

  createEffect(() => {
    if (state.selected.length === 0) {
      setState({ label: "None" });
    } else {
      setState({ label: selectMenu.getLabel() });
    }
  });

  return (
    <SelectMenuContext.Provider value={{ selectMenu }}>
      <div
        class={classNames}
        id={id}
        {...props}
      >
        <button class="btn btn-dropdown btn-small" onClick={menuClick(`#${id} .btn-menu`)}>
          {truncate(state.label)} <ChevronDown />
        </button>
        <Menu>
          {props.children}
        </Menu>
      </div>
    </SelectMenuContext.Provider>
  );
}
