import Archive from "../icons/archive";
import Envelope from "../icons/envelope";
import SquareCheck from "../icons/square-check";
import FilesQueue from "../icons/files-queue";
import BookOpen from "../icons/book-open";
import FileRichText from "../icons/file-rich-text";
import StatsBar from "../icons/stats-bar";
import EnvelopeFull from "../icons/envelope-full";
import FolderOpen from "../icons/folder-open";
import CloudUpload from "../icons/cloud-upload";
import TrafficLights from "../icons/traffic-lights";
import Link from "../icons/link";
import Clipboard from "../icons/clipboard";
import ChevronRight from "../icons/chevron-right";
import FileFont from "../icons/file-font";
import Check from "../icons/check";
import UserNo from "../icons/user-no";
import Email from "../icons/email";
import Book from "../icons/book";
import CircleEmptyCheck from "../icons/circle-empty-check";
import ChatConversation from "../icons/chat-conversation";
import CircleCheck from "../icons/circle-check";
import Wallet from "../icons/wallet";
import Bell from "../icons/bell";
import Code from "../icons/code";
import FilePhoto from "../icons/file-photo";
import LoadDots from "../icons/load-dots";
import User from "../icons/user";
import Dollar from "../icons/dollar";
import Notes from "../icons/notes";
import TriangleAlert from "../icons/triangle-alert";
import Receipt from "../icons/receipt";
import SquareEdit from "../icons/square-edit";
import SquareAlert from "../icons/square-alert";
import UserParents from "../icons/user-parents";
import FileZip from "../icons/file-zip";
import Inbox from "../icons/inbox";
import FileCloud from "../icons/file-cloud";
import File from "../icons/file";
import SatelliteDish from "../icons/satellite-dish";
import Terminal from "../icons/terminal";
import FileText from "../icons/file-text";
import FileVideo from "../icons/file-video";
import ChevronUp from "../icons/chevron-up";
import Music from "../icons/music";
import ChevronDown from "../icons/chevron-down";
import CloudCheck from "../icons/cloud-check";
import Disappearing from "../icons/disappearing";
import Ticket from "../icons/ticket";
import FileVector from "../icons/file-vector";
import Tag from "../icons/tag";
import Tags from "../icons/tags";
import Calendar from "../icons/calendar";
import LoadSpin from "../icons/load-spin";
import VideoCamera from "../icons/video-camera";
import Cloud from "../icons/cloud";
import UserGroup from "../icons/user-group";
import MobilePhone from "../icons/mobile-phone";
import Announcement from "../icons/announcement";
import SquareRemove from "../icons/square-remove";
import StatsCircle from "../icons/stats-circle";
import FileMusic from "../icons/file-music";
import Gift from "../icons/gift";
import Stats from "../icons/stats";
import BookOpenText from "../icons/book-open-text";
import FilePresentation from "../icons/file-presentation";
import Film from "../icons/film";
import ChevronLeft from "../icons/chevron-left";
import Heart from "../icons/heart";
import CircleRemove from "../icons/circle-remove";
import Notebook from "../icons/notebook";
import FileDatabase from "../icons/file-database";
import Pause from "../icons/pause";
import FileScript from "../icons/file-script";
import Invoice from "../icons/invoice";
import StarEmpty from "../icons/star-empty";
import Tablet from "../icons/tablet";
import Bookmark from "../icons/bookmark";
import FileTerminal from "../icons/file-terminal";
import Menu from "../icons/menu";
import Drop from "../icons/drop";
import EnvelopeEmpty from "../icons/envelope-empty";
import Paperclip from "../icons/paperclip";
import Table from "../icons/table";
import FileProgram from "../icons/file-program";
import Send from "../icons/send";
import CashRegisterAlt from "../icons/cash-register-alt";
import Picture from "../icons/picture";
import Coins from "../icons/coins";
import ComputerPcTower from "../icons/computer-pc-tower";
import HandDislike from "../icons/hand-dislike";
import Kiosk from "../icons/kiosk";
import Bank from "../icons/bank";
import Blackboard from "../icons/blackboard";
import ChatMessage from "../icons/chat-message";
import BookLibrary from "../icons/book-library";
import Feather from "../icons/feather";
import Banknotes from "../icons/banknotes";
import Star from "../icons/star";

export default function (iconStr: string) {
  const str = iconStr.replace(/_/g, "-");
  if (str === "archive") return <Archive />;
  else if (str === "envelope") return <Envelope />;
  else if (str === "square-check") return <SquareCheck />;
  else if (str === "files-queue") return <FilesQueue />;
  else if (str === "book-open") return <BookOpen />;
  else if (str === "file-rich-text") return <FileRichText />;
  else if (str === "stats-bar") return <StatsBar />;
  else if (str === "envelope-full") return <EnvelopeFull />;
  else if (str === "folder-open") return <FolderOpen />;
  else if (str === "cloud-upload") return <CloudUpload />;
  else if (str === "traffic-lights") return <TrafficLights />;
  else if (str === "link") return <Link />;
  else if (str === "clipboard") return <Clipboard />;
  else if (str === "chevron-right") return <ChevronRight />;
  else if (str === "file-font") return <FileFont />;
  else if (str === "check") return <Check />;
  else if (str === "user-no") return <UserNo />;
  else if (str === "email") return <Email />;
  else if (str === "book") return <Book />;
  else if (str === "circle-empty-check") return <CircleEmptyCheck />;
  else if (str === "chat-conversation") return <ChatConversation />;
  else if (str === "circle-check") return <CircleCheck />;
  else if (str === "wallet") return <Wallet />;
  else if (str === "bell") return <Bell />;
  else if (str === "code") return <Code />;
  else if (str === "file-photo") return <FilePhoto />;
  else if (str === "load-dots") return <LoadDots />;
  else if (str === "user") return <User />;
  else if (str === "dollar") return <Dollar />;
  else if (str === "notes") return <Notes />;
  else if (str === "triangle-alert") return <TriangleAlert />;
  else if (str === "receipt") return <Receipt />;
  else if (str === "square-edit") return <SquareEdit />;
  else if (str === "square-alert") return <SquareAlert />;
  else if (str === "user-parents") return <UserParents />;
  else if (str === "file-zip") return <FileZip />;
  else if (str === "inbox") return <Inbox />;
  else if (str === "file-cloud") return <FileCloud />;
  else if (str === "file") return <File />;
  else if (str === "satellite-dish") return <SatelliteDish />;
  else if (str === "terminal") return <Terminal />;
  else if (str === "file-text") return <FileText />;
  else if (str === "file-video") return <FileVideo />;
  else if (str === "chevron-up") return <ChevronUp />;
  else if (str === "music") return <Music />;
  else if (str === "chevron-down") return <ChevronDown />;
  else if (str === "cloud-check") return <CloudCheck />;
  else if (str === "disappearing") return <Disappearing />;
  else if (str === "ticket") return <Ticket />;
  else if (str === "file-vector") return <FileVector />;
  else if (str === "tag") return <Tag />;
  else if (str === "tags") return <Tags />;
  else if (str === "calendar") return <Calendar />;
  else if (str === "load-spin") return <LoadSpin />;
  else if (str === "video-camera") return <VideoCamera />;
  else if (str === "cloud") return <Cloud />;
  else if (str === "user-group") return <UserGroup />;
  else if (str === "mobile-phone") return <MobilePhone />;
  else if (str === "announcement") return <Announcement />;
  else if (str === "square-remove") return <SquareRemove />;
  else if (str === "stats-circle") return <StatsCircle />;
  else if (str === "file-music") return <FileMusic />;
  else if (str === "gift") return <Gift />;
  else if (str === "stats") return <Stats />;
  else if (str === "book-open-text") return <BookOpenText />;
  else if (str === "file-presentation") return <FilePresentation />;
  else if (str === "film") return <Film />;
  else if (str === "chevron-left") return <ChevronLeft />;
  else if (str === "heart") return <Heart />;
  else if (str === "circle-remove") return <CircleRemove />;
  else if (str === "notebook") return <Notebook />;
  else if (str === "file-database") return <FileDatabase />;
  else if (str === "pause") return <Pause />;
  else if (str === "file-script") return <FileScript />;
  else if (str === "invoice") return <Invoice />;
  else if (str === "star-empty") return <StarEmpty />;
  else if (str === "tablet") return <Tablet />;
  else if (str === "bookmark") return <Bookmark />;
  else if (str === "file-terminal") return <FileTerminal />;
  else if (str === "menu") return <Menu />;
  else if (str === "drop") return <Drop />;
  else if (str === "envelope-empty") return <EnvelopeEmpty />;
  else if (str === "paperclip") return <Paperclip />;
  else if (str === "table") return <Table />;
  else if (str === "file-program") return <FileProgram />;
  else if (str === "send") return <Send />;
  else if (str === "cash-register-alt") return <CashRegisterAlt />;
  else if (str === "picture") return <Picture />;
  else if (str === "coins") return <Coins />;
  else if (str === "computer-pc-tower") return <ComputerPcTower />;
  else if (str === "hand-dislike") return <HandDislike />;
  else if (str === "kiosk") return <Kiosk />;
  else if (str === "bank") return <Bank />;
  else if (str === "blackboard") return <Blackboard />;
  else if (str === "chat-message") return <ChatMessage />;
  else if (str === "book-library") return <BookLibrary />;
  else if (str === "feather") return <Feather />;
  else if (str === "banknotes") return <Banknotes />;
  else if (str === "star") return <Star />;
  else return ""
}