export function menuClick(selector) {
  return openMenu.bind(null, selector);
}

function openMenu(selector, e) {
  e.preventDefault();
  const menu = document.querySelector(selector);
  if (!menu || menu.classList.contains("active")) { return; }

  // order is important here, otherwise the newly active menu will close
  closeMenus();
  menu.classList.add("active");
  document.addEventListener("click", closeMenus);
}

function closeMenus(e = null) {
  if (e && (e.target.classList.contains("btn-menu-keep") || e.target.hasAttribute("disabled"))) {
    return;
  }
  document.removeEventListener("click", closeMenus);
  const menus = document.querySelectorAll(".btn-menu.active");
  menus.forEach((menu) => {
    menu.classList.remove("active");
  });
}

export function Menu(props) {
  const classNames = (props["class"] || "") + " btn-menu";

  return (
    <ul class={classNames} {...props}>
      {props.children}
    </ul>
  );
}
