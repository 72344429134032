import Request from "../utils/request";

export async function get() {
  const response = await Request.get(`/api/admin/account`);
  return response.account;
}

export async function update(json : object) {
  const response = await Request.patch("/api/admin/account", json);
  return response.account;
}

export async function updateAuth(email : string, password : string) {
  const json = { email, password };
  const response = await Request.patch("/api/admin/account/auth", json);
  return response.account;
}

export default {
  get,
  update,
  updateAuth
};
